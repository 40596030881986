import React                from 'react';
import PropTypes            from 'prop-types';
import {NavLink}            from 'react-router-dom';
import {getNavbarItems}     from '../Navigation/Helper';
import { trackNavbarClick } from '../../../utils/GAHelper';
import NavBarNewIcon        from '../../NavBar/NavBarNewIcon';
import { generateKey } 		from '../../../utils/CommonUtils';

function onClick(event, item) {
	item.gaTracking && trackNavbarClick(item.gaTracking, item.link);
	typeof item.callback === 'function' ? item.callback(event) : null;
}

function getNavbarItem(item, isLoggedIn, idx) {
	if (item.hide) {
		return false;
	}

	return (
		<li key={generateKey('vxnavbar-item', idx)} className="vxnavbar__item-wrapper">
			<NavLink className={"vxnavbar__item" + (item.selected ? " vxnavbar__item--active" : "")} to={item.link} onClick={(event) => onClick(event, item)}>
				<i className={"vxnavbar__item-icon icon " + (item.icon)}>
					{item.count > 0 && <span className={"vxnavbar__item-badge"}>{item.count}</span>}
				</i>
				<span className={"vxnavbar__item-text"}>{item.text}</span>
				{item.new && <div className='vxnavbar__item-new'><NavBarNewIcon /></div>}
			</NavLink>
		</li>
	);
}

function Navbar({isLoggedIn, menus, countUnreadMessages, currentPath}) {
	const navbarItems = getNavbarItems({countUnreadMessages, isLoggedIn, menus, currentPath});

	return (
		<nav className="vxnavbar__wrapper">
			<ul className="vxnavbar">
				{ navbarItems.map((item, idx) => getNavbarItem(item, isLoggedIn, idx))}
			</ul>
		</nav>
	);
}

Navbar.propTypes = {
	isLoggedIn:          PropTypes.bool,
	menus:               PropTypes.object,
	countUnreadMessages: PropTypes.number,
	currentPath:         PropTypes.string,
	
};

export default Navbar;
