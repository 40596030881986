import React        from 'react';
import PropTypes    from 'prop-types';

class FilterButton extends React.Component {

	constructor(props) {
		super(props);

		this.onClick = this.onClickHandler.bind(this);
	}

	onClickHandler() {
		this.props.onClick();
	}

	render() {
		if (!this.props.show) {
			return null;
		}

		return (
			<div className="vxfilter__button" onClick={this.onClick}>
				<i className="vxfilter__button-icon icon -icon-plus-full" />
			</div>
		);
	}
}

FilterButton.propTypes = {
	show:    PropTypes.bool,
	onClick: PropTypes.func.isRequired,
};

FilterButton.defauktProps = {
	show: false,
};

export default FilterButton;