import React                                        from 'react';
import PropTypes                                    from 'prop-types';
import PreviewPictures                              from "./Base/Preview/PreviewPictures";
import {TileDisplayConfigType}                      from "../Grid2";
import MediathekTileFooter, {MediathekTileFooterConfigType} from "./MediathekTileFooter";
import HoverContainer                               from "./Base/HoverContainer";
import ReloadHelper                                 from "../../../utils/ReloadHelper";

const MediathekTile = props => {
	const data          = props.data;
	const displayConfig = props.displayConfig;
	const config        = props.config;
	let preview = {
		aspectRatio: 1.333333,
		id: 0,
		images: [
			{
				size: "w320",
				url: data.previewPicture,
			},
		],
		title: data.title,

	};
	let linkUrl = data.linkVX;
	if(data.mediathekVideo !== undefined){
		linkUrl = data.mediathekVideo.linkVX;
		preview = {
			aspectRatio: 1.333333,
			id: 0,
			images: [
				{
					size: "w320",
					url: data.mediathekVideo.previewPicture,
				},
			],
			title: data.mediathekVideo.title,

		};
	}
	const onClick = e => ReloadHelper.reload(linkUrl, e);
	const onClickCallback = (e) => {
		e.preventDefault();
		data.callback(data);
	};
	
	return (
		<a className={'grid-2__tile__type-video'} href={linkUrl} onClick={data.callback ? onClickCallback : onClick}>
			<HoverContainer>
				<PreviewPictures staticItem={preview}
				                 items={data.previewThumbnails}
				                 displayConfig={displayConfig}
				/>
			</HoverContainer>
			<MediathekTileFooter data={data} displayConfig={displayConfig} config={config.footer} />
		</a>
	);
};

MediathekTile.propTypes = {
	data:          PropTypes.object.isRequired, // VXQL Mediathek type
	config:        PropTypes.shape({
		footer: MediathekTileFooterConfigType,
	}).isRequired,
	displayConfig: TileDisplayConfigType.isRequired,
};

MediathekTile.defaultProps = {
	config: {
		footer: MediathekTileFooter.defaultProps.config,
	},
};

export default MediathekTile;