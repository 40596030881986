'use strict';

import React                from 'react';
import PropTypes            from 'prop-types';
import MobileRoutes         from './MobileRoutes';
import {Routes, Route}      from 'react-router-dom';
import withMobileFooter     from '../Footer/WithMobileFooter';
import LimitedContentVideos from "../MyVisitX/LimitedContentVideos.js";
import { matchPath } from "react-router";
import Flux from '../../../flux/Flux.js';

const WiesnBude                    = React.lazy(() => import("../../Special/WiesnBude/WiesnBude"));
const ActorProfileFeedMobile       = React.lazy(() => import("../Actor/ActorProfileFeed"));
const HomepageIndex                = React.lazy(() => import("../Homepage/HomepageIndex"));
const Cams                         = React.lazy(() => import("../../Cams/Cams.js"));
const ActorProfileOverview         = React.lazy(() => import("../Actor/ActorProfileOverview"));
const Media                        = React.lazy(() => import("../TV/Media"));
const Livestream                   = React.lazy(() => import("../TV/Livestream"));
const Premium                      = React.lazy(() => import("../TV/Premium"));
const Program                      = React.lazy(() => import("../TV/Program"));
const MediaSeriesOverview          = React.lazy(() => import("../TV/MediaSeriesOverview"));
const MediaVideosOverview          = React.lazy(() => import("../TV/MediaVideosOverview"));
const MediaSeries                  = React.lazy(() => import("../TV/MediaSeries"));
const MediaSingle                  = React.lazy(() => import("../TV/MediaSingle"));
const Girls                        = React.lazy(() => import("../TV/Girls"));
const LateNight                    = React.lazy(() => import("../../LateNight/LateNight"));
const BoughtContentAlbums          = React.lazy(() => import("../MyVisitX/BoughtContentAlbums"));
const BoughtContentVideos          = React.lazy(() => import("../MyVisitX/BoughtContentVideos"));
const GiftedContentVideos          = React.lazy(() => import("../MyVisitX/GiftedContentVideos"));
const GiftedContentAlbums          = React.lazy(() => import("../MyVisitX/GiftedContentAlbums"));
const PinboardOverview             = React.lazy(() => import("../MyVisitX/PinboardOverview"));
const PinboardAlbums               = React.lazy(() => import("../MyVisitX/PinboardAlbums"));
const PinboardVideos               = React.lazy(() => import("../MyVisitX/PinboardVideos"));
const Balance                      = React.lazy(() => import("../MyVisitX/Balance"));
const FavoriteActors               = React.lazy(() => import("../MyVisitX/FavoriteActors"));
const MyVideoChats                 = React.lazy(() => import("../MyVisitX/MyVideoChats"));
const ProfileIndex                 = React.lazy(() => import("../MyVisitX/Profile/Index"));
const PrivateCollection            = React.lazy(() => import("../MyVisitX/PrivateCollection"));
const Benefits                     = React.lazy(() => import("../../MyVisitX/Benefits"));
const Spinwheel                    = React.lazy(() => import("../../MyVisitX/Spinwheel/Spinwheel"));
const Mediathek                    = React.lazy(() => import("../../MyVisitX/Mediathek/Mediathek"));
const Goldrush                     = React.lazy(() => import("../../Special/Goldrush/Goldrush"));
const ActorProfileVideosOverview   = React.lazy(() => import("../Actor/ActorProfileVideosOverview"));
const ActorProfileVideosDetails    = React.lazy(() => import("../Actor/ActorProfileVideosDetails"));
const ActorProfileLiveShow         = React.lazy(() => import("../Actor/ActorProfileLiveShow"));
const ActorProfileVideos           = React.lazy(() => import("../Actor/ActorProfileVideos"));
const ActorProfileTopCams          = React.lazy(() => import("../Actor/ActorProfileTopCams"));
const NotFound                     = React.lazy(() => import("../NotFound"));
const ActorSearchCamsNextDoor      = React.lazy(() => import("../ActorSearchCamsNextDoor"));
const ActorLandingPage             = React.lazy(() => import("../ActorLandingPage/ActorLandingPage"));
const Fsk12LandingPage             = React.lazy(() => import("../ActorLandingPage/Fsk12LandingPage"));
const ActorProfile                 = React.lazy(() => import("../Actor/ActorProfile"));
const CategoriesOverview           = React.lazy(() => import("../Categories/CategoriesOverview"));
const MyVisitX                     = React.lazy(() => import("../MyVisitX/MyVisitX"));
const TermsOfService               = React.lazy(() => import("../../TermsOfService"));
const Imprint                      = React.lazy(() => import("../../Imprint"));
const DataProtection               = React.lazy(() => import("../../DataProtection/DataProtection"));
const ComplianceNotice2257         = React.lazy(() => import("../ComplianceNotice2257"));
const Messenger                    = React.lazy(() => import("../Messenger"));
const SearchResult                 = React.lazy(() => import("../Search/SearchResult"));
const Login                        = React.lazy(() => import("../Login"));
const NewsletterUnsubscribe        = React.lazy(() => import("../../NewsletterUnsubscribe"));
const Restricted                   = React.lazy(() => import("../Restricted"));
const MagazineV2Home               = React.lazy(() => import("../Magazine/V2/Home"));
const MagazineV2Archives           = React.lazy(() => import("../Magazine/V2/Archives"));
const VenusThrowback               = React.lazy(() => import("../../Venus/VenusThrowback"));
const AdventCalendar               = React.lazy(() => import("../../AdventCalendar/AdventCalendar.js"));
const TwentyFiveYears              = React.lazy(() => import("../../Special/TwentyFiveYears/TwentyFiveYears.js"));
const VXCalendar                   = React.lazy(() => import("../../Special/VXCalendar/VXCalendar"));
const Lexy360                      = React.lazy(() => import("../Special/Lexy360"));
const WiesnShooting                = React.lazy(() => import("../../Special/Wiesn-Shooting/Wiesn-Shooting"));
const WetAndWild                   = React.lazy(() => import("../../Special/WetAndWild/WetAndWild"));
const VRPorn                       = React.lazy(() => import("../../Special/VRPorn"));
const Magazines                    = React.lazy(() => import("../Magazine/Magazines"));
const MagazineDetails              = React.lazy(() => import("../Magazine/MagazineDetails"));
const BlogArticle                  = React.lazy(() => import("../Magazine/BlogArticle"));
const TopCamsOverview              = React.lazy(() => import("../TopCams/TopCamsOverview"));
const HDCams                       = React.lazy(() => import("../TopCams/HDCams"));
const TV                           = React.lazy(() => import("../TV/TV"));
const ContestOverview              = React.lazy(() => import("../Contests/ContestOverview"));
const ContestDetails               = React.lazy(() => import("../Contests/ContestDetails"));
const VideoStarsMobile             = React.lazy(() => import("../Video/VideoStarsMobile"));
const Category                     = React.lazy(() => import("../Video/Category"));
const VideoIndex                   = React.lazy(() => import("../Video/Index"));
const IndexVIPClub                 = React.lazy(() => import("../IndexVIPClub"));
const VIP30                        = React.lazy(() => import("../../VIPClub/VIP30"));
const Playground                   = React.lazy(() => import("../Playground"));
const Dailybonuspage               = React.lazy(() => import("../Dailybonuspage/Dailybonuspage"));
const NewGirls                     = React.lazy(() => import("../../Cams/NewGirls"));
const FreeChat                     = React.lazy(() => import("../../FreeChat"));
const CamShows                     = React.lazy(() => import("../../CamShows/CamShows"));
const ClashOfCams                  = React.lazy(() => import("../../ClashOfCams/ClashOfCams"));
const UserActivities               = React.lazy(() => import("../../UserActivities/UserActivities"));
const Feed                         = React.lazy(() => import('../../Feed/MixedFeed.js'));
const Easter                       = React.lazy(() => import('../../Easter/Easter.js'));
const CumOnPreload                 = React.lazy(() => import('../../Special/CumOn/CumOnPreload.js'));
const KickItPreload                = React.lazy(() => import('../../Special/KickIt/KickItPreload.js'));
const SlotMachineMobile            = React.lazy(() => import('../../VXGame/SlotMachine/SlotMachineMobile.js'));

function onRouteChange(routeName, routeArgs) {
    // always provide a routeName when asking for incentives on mobile
    if (!routeName) {
        routeName = MobileRoutes.UNKNOWN;
    }
    Flux.VXMobile.setRoute(routeName, routeArgs);

    Flux.Conversion.clearRegion(Flux.Constants.ConversionIncentiveRegions.OVERLAY_FULL_SIZE);
    Flux.Conversion.loadPageLoadIncentives(routeName, routeArgs);
}


function getRouteName(routes, path) {
    return Object.keys(routes).find(key => matchPath({path: routes[key]}, path));
}

function withWrapper(WrappedComponent, props, isExact = false, getCom = false) {
	class withRouterClass extends React.Component {
		render() {
			if (props.params && isExact) {
                const routes      = window.VXConfig.routes;
                const actualRoute = getRouteName(routes, props.location.pathname);
                let args        = {};
                if (actualRoute) {
                    args        = matchPath({ path: window.VXConfig.routes[actualRoute]}, props.location.pathname);
                }
                onRouteChange(actualRoute, args?.params || {});
			}
			return <WrappedComponent {...props} {...this.props} />;
		}
	}

	withRouterClass.propTypes = {
		params:   PropTypes.object,
		location: PropTypes.object,
	};

    if (getCom) {
        return withRouterClass;
    }

    const WR = withRouterClass;
	return <WR />;
}

function withWrapperFooter(WrappedComponent, props, isExact = false) {
    const WRF = withMobileFooter(withWrapper(WrappedComponent, props, isExact, true));
	return <WRF />;
}

class TVRouter extends React.PureComponent {
	render() {
        const baseRoute = MobileRoutes.getPathByName(MobileRoutes.TV);
		return <Routes>
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.TV_MEDIA, baseRoute)} element={withWrapper(Media, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.TV_LIVESTREAM, baseRoute)} element={withWrapper(Livestream, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.TV_PREMIUM_LIVESTREAM, baseRoute)} element={withWrapper(Premium, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.TV_PREMIUM_PRICETABLE, baseRoute)} element={withWrapper(Premium, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.TV_PREMIUM, baseRoute)} element={withWrapper(Premium, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.TV_PROGRAM, baseRoute)} element={withWrapper(Program, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.TV_MEDIA_SERIES_OVERVIEW, baseRoute)} element={withWrapper(MediaSeriesOverview, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.TV_MEDIA_VIDEOS_OVERVIEW, baseRoute)} element={withWrapper(MediaVideosOverview, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.TV_MEDIA_SERIES, baseRoute)} element={withWrapper(MediaSeries, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.TV_SERIES_EPISODE, baseRoute)} element={withWrapper(MediaSingle, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.TV_GIRLS, baseRoute)} element={withWrapper(Girls, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.TV_LATE_NIGHT, baseRoute)} element={withWrapper(LateNight, this.props, true)} />
		</Routes>;
	}
}

class MyVisitXRouter extends React.PureComponent {
    render() {
        const baseRoute = MobileRoutes.getPathByName(MobileRoutes.MYVISITX);
		return <Routes >
			<Route exact path="/" element={withWrapper(Balance, this.props, true)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_FAVORITE_ACTORS, baseRoute)} element={withWrapper(FavoriteActors, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.BOUGHT_CONTENT_OVERVIEW, baseRoute)} element={withWrapper(BoughtContentVideos, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_BOUGHT_PHOTOS, baseRoute)} element={withWrapper(BoughtContentAlbums, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_BOUGHT_VIDEOS, baseRoute)} element={withWrapper(BoughtContentVideos, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_VIDEOS_GIFT, baseRoute)} element={withWrapper(GiftedContentVideos, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_VIDEOS_LIMITED, baseRoute)} element={withWrapper(LimitedContentVideos, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_PHOTOS_GIFT, baseRoute)} element={withWrapper(GiftedContentAlbums, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_MY_VIDEOCHATS, baseRoute)} element={withWrapper(MyVideoChats, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_GUEST_PROFILE, baseRoute)} element={withWrapper(ProfileIndex, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_GUEST_PROFILE_DATA, baseRoute)} element={withWrapper(ProfileIndex, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_GUEST_PROFILE_PREFS, baseRoute)} element={withWrapper(ProfileIndex, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_VIDEOS_PRIVATE_COLLECTION, baseRoute)} element={withWrapper(PrivateCollection, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_PHOTOS_PRIVATE_COLLECTION, baseRoute)} element={withWrapper(PrivateCollection, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_PRIVATE_COLLECTION, baseRoute)} element={withWrapper(PrivateCollection, this.props)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_PINBOARD_PHOTOS, baseRoute)} element={withWrapper(PinboardOverview, this.props, true)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_PINBOARD_PHOTOS_ALBUM, baseRoute)} element={withWrapper(PinboardAlbums, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_PINBOARD_VIDEOS, baseRoute)} element={withWrapper(PinboardVideos, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_BENEFITS, baseRoute)} element={withWrapper(Benefits, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_SPINWHEEL, baseRoute)}element={withWrapperFooter(Spinwheel, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_MEDIATHEK, baseRoute, true)} element={withWrapperFooter(Mediathek, this.props)} />
		</Routes>;
	}
}

class ActorProfileVideosRouter extends React.PureComponent {
	render() {
        const baseRoute = MobileRoutes.getPathByName(MobileRoutes.ACTOR_PROFILE_VIDEOS);
		return <Routes>
			<Route exact path={"/"} element={withWrapper(ActorProfileVideosOverview, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.ACTOR_PROFILE_VIDEOS_CLASSIC, baseRoute)} element={withWrapper(ActorProfileVideosOverview, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.ACTOR_PROFILE_VIDEOS_TAG_GROUP, baseRoute)} element={withWrapper(ActorProfileVideosOverview, this.props, true)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.ACTOR_PROFILE_VIDEOALBUM_DETAIL, baseRoute)} element={withWrapper(ActorProfileVideosDetails, this.props, true)} />
		</Routes>;
	}
}

class ActorProfileRouter extends React.PureComponent {
	render() {
        const baseRoute    = MobileRoutes.getPathByName(MobileRoutes.ACTOR_PROFILE_OVERVIEW);
		const overviewComp = this.props.isFeedAvailable ? ActorProfileFeedMobile : ActorProfileOverview;
		return <Routes>
			<Route exact path="/" element={withWrapper(overviewComp, this.props, true)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.ACTOR_PROFILE_ABOUT, baseRoute)} element={withWrapper(ActorProfileOverview, {...this.props, isAbout: true})} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.ACTOR_PROFILE_LIVE_SHOW, baseRoute)} element={withWrapper(ActorProfileLiveShow, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.ACTOR_PROFILE_VIDEOS, baseRoute, true)} element={withWrapper(ActorProfileVideos, this.props)} />
			{this.props.isTopCamsAvailable && <Route path={MobileRoutes.getPathByName(MobileRoutes.ACTOR_PROFILE_TOP_CAMS, baseRoute)} element={withWrapper(ActorProfileTopCams, this.props)} />}
			<Route path="*" element={withWrapper(NotFound, this.props)} />
		</Routes>;
	}
}

ActorProfileRouter.propTypes = {
	isFeedAvailable:    PropTypes.bool,
	isTopCamsAvailable: PropTypes.bool,
};

ActorProfileRouter.defaultProps = {
	isFeedAvailable:    false,
	isTopCamsAvailable: false,
};

class DefaultRouter extends React.Component {
    shouldComponentUpdate(nextProps) {
        // only re-render, when location changes
        const routeChange = JSON.stringify(this.props.location) !== JSON.stringify(nextProps.location);
        if (this.props.location.pathname !== nextProps.location.pathname) {
           Flux.VXMobile.showNavbar();
        }
		return routeChange;
	}

	render() {
		return <Routes>
			{/* ActorSearch routes */}
			<Route exact path="/" element={withWrapper(HomepageIndex, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.VXMOBILE)} element={withWrapper(HomepageIndex, this.props, true)} />
			{/*'/de/' */}
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.CAMS)} element={withWrapperFooter(Cams, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.CAMS_SLUG)}element={withWrapperFooter(Cams, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.CATEGORIES_CAMS_NEXT_DOOR)} element={withWrapper(ActorSearchCamsNextDoor, this.props, true)} />

			{/* ActorLandingPage routes */}
			<Route path={MobileRoutes.getPathByName(MobileRoutes.ACTOR_LANDINGPAGE_LOCALIZED)} element={withWrapper(ActorLandingPage, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.ACTOR_LANDINGPAGE_FSK12)} element={withWrapper(Fsk12LandingPage, this.props)} />

			{/* ActorProfile routes */}
			<Route path={MobileRoutes.getPathByName(MobileRoutes.ACTOR_PROFILE_OVERVIEW, "", true)} element={withWrapper(ActorProfile, this.props)} />

			{/* Categories routes */}
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.CATEGORIES_OVERVIEW)} element={withWrapper(CategoriesOverview, this.props, true)} />

			{/* MyVisitX routes - order is important! */}
			<Route path={MobileRoutes.getPathByName(MobileRoutes.BOUGHT_CONTENT_OVERVIEW)} element={withWrapper(MyVisitX, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX_PINBOARD_PHOTOS)} element={withWrapper(MyVisitX, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MYVISITX, "", true)} element={withWrapper(MyVisitX, this.props)} />

			<Route path={MobileRoutes.getPathByName(MobileRoutes.TERMS_OF_SERVICE)} element={withWrapper(TermsOfService, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.IMPRINT)} element={withWrapper(Imprint, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.DATA_PROTECTION)} element={withWrapper(DataProtection, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.COMPLIANCE_NOTICE_2257)} element={withWrapper(ComplianceNotice2257, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MESSENGER)} element={withWrapper(Messenger, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.SEARCH_RESULTS_ACTORS)} element={withWrapper(SearchResult, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.SEARCH_RESULTS_VIDEOS)} element={withWrapper(SearchResult, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.SEARCH_RESULTS_PHOTOS)} element={withWrapper(SearchResult, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.SEARCH_RESULTS_MEDIATHEK)} element={withWrapper(SearchResult, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.SEARCH_RESULTS_MAGAZINE)} element={withWrapper(SearchResult, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.SEARCH_RESULTS)} element={withWrapper(SearchResult, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.LOGIN)} element={withWrapper(Login, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.NEWSLETTER_UNSUBSCRIBE)} element={withWrapper(NewsletterUnsubscribe, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.RESTRICTED)} element={withWrapper(Restricted, this.props)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.MAGAZINE)} element={withWrapper(MagazineV2Home, this.props, true)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MAGAZINE_ARCHIVE)} element={withWrapper(MagazineV2Archives, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.SPECIAL_EASTER)} element={withWrapper(Easter, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.SPECIAL_VENUS_THROWBACK)} element={withWrapper(VenusThrowback, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.SPECIAL_ADVENT_CALENDAR)} element={withWrapper(AdventCalendar, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.SPECIAL_TWENTY_FIVE_YEARS)} element={withWrapper(TwentyFiveYears, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.SPECIAL_VX_CALENDAR)} element={withWrapper(VXCalendar, this.props)} />
			{/*<Route exact path={MobileRoutes.getPathByName(MobileRoutes.SPECIAL_SLOT_MACHINE_DIGIBET)}element={withWrapperFooter(SlotMachine, this.props, true)} />*/}
			{/*<Route exact path={MobileRoutes.getPathByName(MobileRoutes.SPECIAL_SLOT_MACHINE_ARCHIVE)}element={withWrapperFooter(SlotMachine, this.props, true)} />*/}
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.SPECIAL_SLOT_MACHINE)}element={withWrapperFooter(SlotMachineMobile, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.SPECIAL_LEXY360)} element={withWrapper(Lexy360, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.SPECIAL_WIESN_SHOOTING)} element={withWrapper(WiesnShooting, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.SPECIAL_WET_AND_WILD)} element={withWrapper(WetAndWild, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.SPECIAL_CUM_ON)} element={withWrapper(CumOnPreload, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.SPECIAL_KICK_IT_2021)} element={withWrapper(KickItPreload, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.SPECIAL_WIESN_BUDE)} element={withWrapper(WiesnBude, this.props, true)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.SPECIAL_VR_PORN)} element={withWrapper(VRPorn, this.props)} />
            <Route path={MobileRoutes.getPathByName(MobileRoutes.SPECIAL_GOLDRUSH)}element={withWrapperFooter(Goldrush, this.props)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.MAGAZINE_MAGAZINES_OVERVIEW)} element={withWrapper(Magazines, this.props, true)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.MAGAZINE_MAGAZINES_DETAILS)} element={withWrapper(MagazineDetails, this.props)} />
			{/* this route must be the last magazine route, otherwise the slug will contain all /magazine/value/ routes */}
			<Route path={MobileRoutes.getPathByName(MobileRoutes.BLOG_ARTICLE)} element={withWrapper(BlogArticle, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.ADWORDS_LANDING_PAGE)} element={withWrapper(Cams, this.props)} />

			{/* TopCams routes */}
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.CATEGORIES_TOP_CAMS)} element={withWrapper(TopCamsOverview, this.props, true)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.CATEGORIES_TOP_CAMS_TOP_FAVORITES)} element={withWrapper(TopCamsOverview, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.CATEGORIES_TOP_CAMS_BEST_CHATS)} element={withWrapper(TopCamsOverview, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.CATEGORIES_TOP_CAMS_HD_CAMS)} element={withWrapper(HDCams, this.props)} />

			{/* TV routes - wrapper */}
			<Route path={MobileRoutes.getPathByName(MobileRoutes.TV, "", true)} element={withWrapper(TV, this.props)} />

			{/* Contests routes */}
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.CONTESTS_OVERVIEW)} element={withWrapper(ContestOverview, this.props, true)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.CONTESTS_DETAILS_TOP10)} element={withWrapper(ContestDetails, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.CONTESTS_DETAILS_NOT_VOTED)} element={withWrapper(ContestDetails, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.CONTESTS_DETAILS_VOTED)} element={withWrapper(ContestDetails, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.CONTESTS_DETAILS_ACTOR)} element={withWrapper(ContestDetails, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.CONTESTS_DETAILS)} element={withWrapper(ContestDetails, this.props)} />

			{/** Video section (order matters) */}
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.VIDEOS_STARS)} element={withWrapper(VideoStarsMobile, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.VIDEOS_ALL_CATEGORY)} element={withWrapper(Category, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.VIDEOS_NEW_CATEGORY)} element={withWrapper(Category, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.VIDEOS_TRENDING_CATEGORY)} element={withWrapper(Category, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.VIDEOS_FREE_CLIPS_CATEGORY)} element={withWrapper(Category, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.VIDEOS_TV_SERIES_CATEGORY)} element={withWrapper(Category, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.VIDEOS_ON_SALE_CATEGORY)} element={withWrapper(Category, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.VIDEOS_INDEX)} element={withWrapper(VideoIndex, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.VIP_CLUB)} element={withWrapper(IndexVIPClub, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.VIP30)}element={withWrapperFooter(VIP30, this.props, true)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.PLAYGROUND)} element={withWrapper(Playground, this.props)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.DAILYBONUSPAGE)} element={withWrapper(Dailybonuspage, this.props, true)} />
			<Route exact path={MobileRoutes.getPathByName(MobileRoutes.ACTOR_SEARCH_NEW_GIRLS)} element={withWrapper(NewGirls, this.props, true)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.FREE_CHAT)} element={withWrapper(FreeChat, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.CAM_SHOWS)} element={withWrapper(CamShows, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.FEED)}element={withWrapperFooter(Feed, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.CLASHOFCAMS_EVENT)}element={withWrapperFooter(ClashOfCams, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.USER_ACTIVITIES)} element={withWrapper(UserActivities, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.USER_ACTIVITIES_CHALLENGE)} element={withWrapper(UserActivities, this.props)} />
			<Route path={MobileRoutes.getPathByName(MobileRoutes.USER_ACTIVITIES_HIGH_ON_ROSE)} element={withWrapper(UserActivities, this.props)} />

			{/* default not found route */}
			<Route path="*" element={withWrapper(NotFound, this.props)} />
		</Routes>;
	}
}

DefaultRouter.propTypes = {
	location: PropTypes.object,
	params:    PropTypes.object,
};

export {
	ActorProfileRouter,
	ActorProfileVideosRouter,
	DefaultRouter,
	MyVisitXRouter,
	TVRouter,
};

