import React        from 'react';
import {VXPay}      from '../../../utils/VXPay';
import PropTypes    from 'prop-types';
import Translations from '../../../utils/Translations';

function onLoginClick(event) {
	event.preventDefault();
	VXPay.openLogin();
}

function onSignupClick(event) {
	event.preventDefault();
	VXPay.openSignup();
}

function Login(props) {
	return (
		<div className={"vxheader__button-wrapper" + (props.position ? ' vxheader__button-wrapper--' + props.position : '')}>
			<a href="#" className="vxheader__button vxheader__button--signup" onClick={onSignupClick}>
				<i className="icon -icon-user-full" />
				{Translations.get('NavbarLabelSignup')}
			</a>
			<a className="vxheader__button vxheader__button--signin" onClick={onLoginClick}>
				<i className="icon -icon-user-full" />
				{Translations.get('NavbarLabelLogin')}
			</a>
		</div>
	);
}

Login.propTypes = {
	position: PropTypes.string,
};

Login.defaultProps = {
	position: '',
};

export default Login;