import React              from 'react';
import PropTypes          from 'prop-types';
import Flux               from '../../../flux/Flux';
import Translations       from '../../../utils/Translations';
import TileGridBox        from '../TileGrid/TileGridBox';
import HistoryStateHelper from '../../../utils/HistoryStateHelper';
import {withOrientation}  from '../../OrientationWrapper';
import CamsSeoTeaser      from "../../Cams/CamsSeoTeaser.js";


class LimitedContentVideos extends React.Component {

	constructor(props) {
		super(props);

		this.restoreScrollPosition = HistoryStateHelper.hasKey(Flux.Constants.HistoryStateKeys.RESTORE_SCROLL_POSITION);
	}

	componentDidMount() {
		window.setTimeout(() => {
			document.documentElement.scrollTop = 0;
		});

		const scrollPosition = Flux.VXMobile.getScrollToOnMount(this.props.location.pathname);
		if (scrollPosition && this.restoreScrollPosition) {
			document.getElementById(this.props.containerId).scrollTop = scrollPosition;
		}
	}

	componentWillUnmount() {
		if (typeof Flux.VXMobile.setScrollToOnMount(this.props.location.pathname) !== 'undefined') {
			Flux.VXMobile.setScrollToOnMount(this.props.location.pathname, document.getElementById(this.props.containerId).scrollTop);
		}
	}

	render() {
		const description = <CamsSeoTeaser text={Translations.get('MediaPanelLimitedVideosTooltip')} />;

		const content = <div className={'myvisitx-content-wrapper'}>
			<div id="actor-scroll-top-anchor" />
			<TileGridBox
				type={Flux.Constants.TileGrid.LIMITED_VIDEOS}
				initialPayload={{isActorNameVisible: true}}
				defaultSort=""
				scrollToId={"actor-scroll-top-anchor"}
				initialCols={this.props.isLandscape ? 2 : 1}
				usePagination={true}
				useSorting={true}
				useCache={true}
				useTileWithSecondary={false}
				scrollAnchorId={'actor-scroll-top-anchor'}
				scrollContainerId={this.props.containerId}
				maxEntriesForPlaceholder={4}
				tileGridId={'myvisitxBoughtContentVideos'}
				useVXHeadline={true}
				headline={Translations.get('MediaPanelLimitedVideos')}
				description={description}
				showDescription={true}
				hasBreadcrumb={false}
			/>
		</div>;

		return (
			<div>
				{content}
			</div>
		);
	}
}

LimitedContentVideos.propTypes = {
	isLandscape: PropTypes.bool,
	location:    PropTypes.object,
    containerId: PropTypes.string,
};

export default withOrientation(LimitedContentVideos);
