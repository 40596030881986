import React  from "react";
import Footer from '../Footer/Footer';

function withMobileFooter(WrappedComponent, ...props) {
	class withMobileFooter extends React.Component {
		render() {
			return <React.Fragment>
                        <WrappedComponent {...props} />
                        <Footer withMargin={true} />
            </React.Fragment>;
		}
	}

	return withMobileFooter;
}

export default withMobileFooter;
