import React from 'react';
import Login from '../Login';

class NoPermission extends React.Component{
	render() {
		return (
			<div>
				<Login />
			</div>
		);
	}
}

export default NoPermission;