import React                  from 'react';
import PropTypes              from 'prop-types';
import SidebarWrapper, {FROM} from './SidebarWrapper';
import Flux                   from '../../../flux/Flux';
import Translations           from '../../../utils/Translations';
import NavbarSearchResult     from '../Navbar/NavbarSearchResult';
import SecondSearchContext    from '../../../context/SecondSearchContext';
import withNavigate from '../../HigherOrderComponents/Utility/withNavigate';

const MIN_SEARCH_PATTERN_LENGTH = 3;
class SearchMenu extends React.PureComponent {

	static getInitialState() {
		return {
			inputFocused:          true,
			searchPattern:         '', // current pattern
			moreLink:              '',
			resultAvailable:       false,
			active:                false,
			smallLogo:             true,
			proposals:             [],
			actorsSearchResult:    {},
			videosSearchResult:    {},
			mediathekSearchResult: {},
			blogSearchResult:      {},
			suggestions:           [],
			lastSearchRequests:    [],
			searchUrl:             Flux.GlobalSearch.getSearchUrl(),
			isOpen:                false,
			isInputChanged:        false,
			showOnlyProposals:     false,
			showClearBtn:          false,
		};
	}

	constructor(props) {
		super(props);

		this.state = SearchMenu.getInitialState();
		this.inputField;

		this.onMenuChange             = this.onMenuChange.bind(this);
		this.closeMenu                = this.closeMenu.bind(this);
		this.onInputChange            = this.onInputChange.bind(this);
		this.clearSearchPattern       = this.clearSearchPattern.bind(this);
		this.onSearchResult           = this.onSearchResult.bind(this);
		this.setSearchPattern         = this.setSearchPattern.bind(this);
		this.onKeyPress               = this.onKeyPress.bind(this);
		this.handleProcessSuggestions = this.handleProcessSuggestions.bind(this);
		this.setFocus                 = this.setFocus.bind(this);
		this.getActiveTabLink         = this.getActiveTabLink.bind(this);
        this.openLink                 = this.openLink.bind(this);

	}

	componentDidMount() {
		Flux.GlobalSearch.processSuggestions(this.handleProcessSuggestions);
		Flux.VXMobile.addMenuChangeListener(this.onMenuChange);
		Flux.GlobalSearch.addSearchResultListener(this.onSearchResult);
	}

	componentWillUnmount() {
		Flux.VXMobile.removeMenuChangeListener(this.onMenuChange);
		Flux.GlobalSearch.removeSearchResultListener(this.onSearchResult);
	}

	handleProcessSuggestions(result) {
		const state = {};
		const data  = result.data.search;
		if (typeof  data !== 'undefined') {
			if (data.lastSearchRequests && data.lastSearchRequests.length > 0) {
				state.lastSearchRequests = data.lastSearchRequests;
			}
			if (data.suggestions && data.suggestions.length > 0) {
				state.suggestions = data.suggestions;
			}
			this.setState(state);
		}
	}

	onInputChange(e) {
		e.preventDefault();
		e.stopPropagation();
		const searchPattern = e.target.value;
		this.setSearchPattern(searchPattern);
		this.setState({
			searchPattern:  searchPattern,
			isInputChanged: true,
		});
	}

	clearSearchPattern(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setSearchPattern('');
	}

	onSearchResult() {
		const searchResult = Flux.GlobalSearch.getSearchResult();
		if (searchResult) {
			if (searchResult.searchPattern !== this.state.searchPattern) {
				return;
			}

			this.setState({
				proposals:             searchResult.proposals,
				actorsSearchResult:    searchResult.actors,
				videosSearchResult:    searchResult.videos,
				mediathekSearchResult: searchResult.mediathek,
				blogSearchResult:      searchResult.blog,
				resultAvailable:       true,
				lastSearchRequests:    Flux.GlobalSearch.getLastSearchRequests(),
				showOnlyProposals:     false,
			});
		} else {
			this.setState({
				resultAvailable: true,
			});
		}
	}

	setSearchPattern(searchPattern, isExtended = false) {
		const keyPattern   = /^[a-z0-9äöüÄÖÜß -]*$/i; // @see ArgumentFilter
		const isFirstSpace = searchPattern.length > 0 && !searchPattern.replace(/\s/g, '').length;

		if (!keyPattern.test(searchPattern) || isFirstSpace) {
			return;
		} else if (searchPattern.length < MIN_SEARCH_PATTERN_LENGTH) {
			this.setState({
				resultAvailable: false,
				searchPattern:   searchPattern,
				inputFocused:    true,
				showClearBtn:    false,
			});
		} else {
			if (isExtended) {
				this.inputField.blur();
				Flux.GlobalSearch.storeSearchRequest(this.state.searchPattern);
			}
			this.setState({searchPattern: searchPattern, showOnlyProposals: true, inputFocused: false, showClearBtn: true });
			Flux.GlobalSearch.doSearch(searchPattern, isExtended);
		}
	}

	onMenuChange() {
		const menuOpen = Flux.VXMobile.getMenuOpen();
		if (menuOpen !== Flux.Constants.VXMobile.Menus.SEARCH) {
			return;
		}
		const menu     = Flux.VXMobile.getMenu(menuOpen);

		if (!menu.isOpen) {
			this.setSearchPattern('');
		}

		if (menu.isOpen !== this.state.isOpen) {
			this.setState({
				isOpen: menu.isOpen,
				menuType: menuOpen,
			});
		}
	}

	onKeyPress(e) {
		switch (e.keyCode) {
			case 13:
				if(this.props.search){
					if(typeof this.getActiveTabLink() !== 'undefined' && this.getActiveTabLink().split('/').slice(0, -1)[3] === e.target.value){
						this.openLink(this.getActiveTabLink());
						this.setSearchPattern(e.target.value);
					}

					if(typeof this.getActiveTabLink() === 'undefined' || (typeof this.getActiveTabLink() !== 'undefined' && this.getActiveTabLink().split('/').slice(0, -1)[3] !== e.target.value)){
						this.props.navigate({
							pathname: window.location.pathname.replace(this.props.searchPattern,  e.target.value),
							search: `?category=${this.props.activeTab}`,
						});
					}

					if(typeof this.props.searchPattern === 'undefined'){
						this.openLink(window.location.href);
					}
					this.props.setSearchPattern(e.target.value),
					this.setState({
						isInputChanged: true,
					});
				}
				else {
					this.setSearchPattern(this.state.searchPattern, true);
				}
				break;
			default:
				break;
		}
	}

	getActiveTabLink(){
		let activeTabLink;
		switch(this.props.activeTab) {
			case Flux.Constants.SearchResults.Categories.actors.name:
				activeTabLink = this.state.actorsSearchResult.link;
				break;
			case Flux.Constants.SearchResults.Categories.videos.name:
				activeTabLink = this.state.videosSearchResult.link;
				break;
			case Flux.Constants.SearchResults.Categories.tv.name:
				activeTabLink = this.state.mediathekSearchResult.link;
				break;
			case Flux.Constants.SearchResults.Categories.blog.name:
				activeTabLink = this.state.blogSearchResult.link;
				break;
			default:
				activeTabLink = this.state.actorsSearchResult.link;
		}
		return activeTabLink;
	}

	openLink(link, storeResult = false) {
		if (storeResult) {
			Flux.GlobalSearch.storeSearchRequest(this.props.searchPattern);
		}
		this.props.navigate(link);
	}

	closeMenu() {
		this.setSearchPattern('');
		Flux.VXMobile.closeMenu(this.state.menuType);
		this.setState({
			isOpen: false,
			menuType: null,
		});
	}

	setFocus(ref) {
		if (ref && this.state.inputFocused && this.state.menuType === Flux.Constants.VXMobile.Menus.SEARCH) {
			this.inputField = ref;
			ref.focus();
		}
	}

	render() {
		/*if ((this.state.menuType !== Flux.Constants.VXMobile.Menus.SEARCH) || !this.state.isOpen) {
			return null;
		}*/
		const input = callback => (
			<div className={"navigation-search__header"}>
				<i className={"navigation-main__icon -icon-search-full"}></i>
				<div className={"navigation-search__header-input__container"}>
					<form className="navigation-search__header-form" action="#">
						<input className={"navigation-search__header-input"}
						       placeholder={Translations.get('NavbarSearchSearchFor')}
						       value={(this.props.search && !this.state.isInputChanged) ? this.props.searchPattern : this.state.searchPattern}
						       onChange={this.onInputChange}
						       onKeyDown={this.onKeyPress}
						       ref={(ref) => {
							       this.setFocus(ref);
						       }}
						       autoFocus={this.props.search ? this.props.autoFocus : false}
						       autoComplete="off"
						       type="search"
						/>
					</form>
					{this.state.showClearBtn && <div onClick={this.clearSearchPattern} className={"navigation-search__header-clear"}>
						<i className={"icon -icon-round-close"}></i>
					</div>}
				</div>
				<div className={"navigation-search__header-close"} onClick={callback}>{Translations.get('BtnCancel')}</div>
			</div>
		);

		if(this.props.search && !this.state.isInputChanged) {
			return (
				<SecondSearchContext.Consumer>
					{context => {
						const action = () => {
							context.updateTopSearchShowed(val => {
								this.props.parentCallback(val.secondSearchShowed);
							}, true, false);
						};
						return (<div>{input(action)}</div>);
					}
					}
				</SecondSearchContext.Consumer>
			);
		}

		return (
			<div className={this.props.search ? "search-results-container" : ''} id="navigation-search__container" >
				{input(this.closeMenu)}
				<NavbarSearchResult
					id="navbarSearchResult"
					suggestions={this.state.suggestions}
					proposals={this.state.proposals}
					resultAvailable={this.state.resultAvailable}
					searchPattern={this.state.searchPattern}
					actorsSearchResult={this.state.actorsSearchResult}
					blogSearchResult={this.state.blogSearchResult}
					mediathekSearchResult={this.state.mediathekSearchResult}
					videosSearchResult={this.state.videosSearchResult}
					lastSearchRequests={this.state.lastSearchRequests}
					setSearchPattern={this.setSearchPattern}
					minSearchPatternLength={MIN_SEARCH_PATTERN_LENGTH}
					showOnlyProposals={this.state.showOnlyProposals}
					isResultPageSearch={this.props.search}
				/>
			</div>
		);
	}
}


class SearchSidebar extends React.Component{

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<SidebarWrapper openMenu={this.props.isMenuOpen} showShadow={false} from={FROM.RIGHT} showNavbar={false}>
				<SearchMenu  {...this.props} />
			</SidebarWrapper>
		);
	}

}

SearchSidebar.propTypes = {
	isMenuOpen:    PropTypes.bool,
};

SearchMenu.propTypes = {
	search:           PropTypes.bool,
	searchPattern:    PropTypes.string,
	parentCallback:   PropTypes.func,
	activeTab:        PropTypes.string,
	setSearchPattern: PropTypes.string,
	autoFocus:        PropTypes.bool,
    navigate:         PropTypes.func,
};

SearchMenu.defaultProps = {
	autoFocus: true,
};

export default withNavigate(SearchSidebar);
export {SearchMenu};
