import MobileRoutes from '../components/VXMobile/Router/MobileRoutes';

class RouterHelper {

	static isCamsRoute(path) {
		const camsPath     = MobileRoutes.getLink(MobileRoutes.CAMS);
		const camsSlugBasePath = MobileRoutes.getLink(MobileRoutes.CAMS_SLUG, {':slug': ':slug'}).replace(':slug/', '');

		return path === camsPath || path.startsWith(camsSlugBasePath);
	}

	/**
	 * @param {String} path
	 * @return {boolean}
	 */
	static isNewHomepage(path) {
		return [
			MobileRoutes.getLink(MobileRoutes.VXMOBILE),
			'/',
		].indexOf(path) >= 0;
	}

	/**
	 * @param uri
	 */
	static getBaseUriAsDesktopSite(uri) {
		return uri.replace('//m.new.', '//www.').replace('//m.', '//www.');
	}
}

export default RouterHelper;