import FooterRow                   from './Base/Footer/FooterRow';
import FooterColumn                from './Base/Footer/FooterColumn';
import FooterLabel                 from './Base/Footer/FooterLabel';
import {mergeDeep}                 from '../../../utils/CommonUtils';
import Footer                      from './Base/Footer/Footer';
import React                       from 'react';
import Flux                        from '../../../flux/Flux';
import {TileDisplayConfigType}     from '../Grid2';
import PropTypes                   from 'prop-types';

const FooterLabelStyles = Flux.Constants.Grid2.Footer.Label.Styles;

const BlogTileFooter = props => {
	const data          = props.data;
	const displayConfig = props.displayConfig;
	const config        = mergeDeep({}, BlogTileFooter.defaultProps.config, props.config);
	if (!config.show.footer) {
		return null;
	}

	const showBuyBtn    = config.show.buyBtn;

	const labelFontScaling = showBuyBtn ? Flux.Constants.Grid2.Footer.Label.FontSizeScaling.HALF : Flux.Constants.Grid2.Footer.Label.FontSizeScaling.NONE;

	return (
		<Footer displayConfig={displayConfig} additionalClasses={["grid-2__tile__type-blog__footer"]}>
			<FooterRow >
				<FooterRow shrink={true} grow={true}>
					<FooterColumn grow={true} shrink={true}>
						<FooterRow>
							<FooterLabel fontSizeScaling={labelFontScaling} labelStyle={FooterLabelStyles.TITLE}>{data.title}</FooterLabel>
						</FooterRow>
						<FooterRow>
							<FooterColumn grow={true} shrink={true}>
								<FooterRow>
									<FooterLabel fontSizeScaling={labelFontScaling} labelStyle={FooterLabelStyles.SUBTITLE}>
										{data.subTitle}
									</FooterLabel>
								</FooterRow>
							</FooterColumn>
						</FooterRow>
					</FooterColumn>
				</FooterRow>
			</FooterRow>
		</Footer>
	);
};

export const BlogTileFooterConfigType = PropTypes.shape({
	show: PropTypes.shape({
		actorName: PropTypes.bool,
		likes:     PropTypes.bool,
		buyBtn:    PropTypes.bool,
		footer:    PropTypes.bool,
	}),
});

BlogTileFooter.propTypes = {
	data:          PropTypes.object.isRequired, // VXQL Blog type
	config:        BlogTileFooterConfigType,
	displayConfig: TileDisplayConfigType,
};

BlogTileFooter.defaultProps = {
	config: {
		show: {
			actorName: true,
			likes:     false,
			buyBtn:    false,
			footer:    true,
		},
	},
};

export default BlogTileFooter;