import FooterRow                   from './Base/Footer/FooterRow';
import FooterColumn                from './Base/Footer/FooterColumn';
import FooterLabel                 from './Base/Footer/FooterLabel';
import {formatDuration, mergeDeep} from '../../../utils/CommonUtils';
import Footer                      from './Base/Footer/Footer';
import React                       from 'react';
import Flux                        from '../../../flux/Flux';
import {TileDisplayConfigType}     from '../Grid2';
import PropTypes                   from 'prop-types';
import ReloadHelper                from '../../../utils/ReloadHelper';

const FooterLabelStyles = Flux.Constants.Grid2.Footer.Label.Styles;

const MediathekTileFooter = props => {
	const data          = props.data;
	const displayConfig = props.displayConfig;
	const config        = mergeDeep({}, MediathekTileFooter.defaultProps.config, props.config);
	if (!config.show.footer) {
		return null;
	}

	const showLikes     = config.show.likes;
	const showActorName = config.show.actorName && !showLikes;
	const showBuyBtn    = config.show.buyBtn;

	//const actorLinkUrl  = data.models.items.length > 0 ?  data.models.items[0].linkVX  : '';
	const actorLinkUrl  = data.linkVX;
	const actorName     = data.models.items.length > 0 ?  data.models.items[0].name  : '';
	const onActorNameClick = showActorName ? e => ReloadHelper.reload(actorLinkUrl, e) : null;
	const labelFontScaling = showBuyBtn ? Flux.Constants.Grid2.Footer.Label.FontSizeScaling.HALF : Flux.Constants.Grid2.Footer.Label.FontSizeScaling.NONE;

	const actorNameLabel = <FooterLabel fontSizeScaling={labelFontScaling} labelStyle={FooterLabelStyles.ACTOR_NAME}>
		<a href={actorLinkUrl} onClick={onActorNameClick}>{actorName}</a>
	</FooterLabel>;
	return (
		<Footer displayConfig={displayConfig}>
			<FooterRow >
				<FooterRow shrink={true} grow={true}>
					<FooterColumn grow={true} shrink={true}>
						<FooterRow>
							<FooterLabel fontSizeScaling={labelFontScaling} labelStyle={FooterLabelStyles.TITLE}>{(props.data.mediathekVideo !== undefined) ? data.mediathekVideo.title : data.title}</FooterLabel>
						</FooterRow>
						<FooterRow>
							<FooterColumn grow={true} shrink={true}>
								<FooterRow>
									<FooterLabel fontSizeScaling={labelFontScaling} labelStyle={FooterLabelStyles.SUBTITLE}>
										{(props.data.mediathekVideo !== undefined) ? formatDuration(data.mediathekVideo.duration) : formatDuration(data.duration)}
									</FooterLabel>
									{showActorName && showBuyBtn && actorNameLabel}
								</FooterRow>
							</FooterColumn>
							{showActorName && !showBuyBtn && <FooterColumn>
								{actorNameLabel}
							</FooterColumn>}
						</FooterRow>
					</FooterColumn>
				</FooterRow>
			</FooterRow>
		</Footer>
	);
};

export const MediathekTileFooterConfigType = PropTypes.shape({
	show: PropTypes.shape({
		actorName: PropTypes.bool,
		likes:     PropTypes.bool,
		buyBtn:    PropTypes.bool,
		footer:    PropTypes.bool,
	}),
});

MediathekTileFooter.propTypes = {
	data:          PropTypes.object.isRequired, // VXQL video type
	config:        MediathekTileFooterConfigType,
	displayConfig: TileDisplayConfigType,
};

MediathekTileFooter.defaultProps = {
	config: {
		show: {
			actorName: true,
			likes:     false,
			buyBtn:    false,
			footer:    true,
		},
	},
};

export default MediathekTileFooter;