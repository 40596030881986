import React        from 'react';
import Translations from '../../utils/Translations';
import {VXPay}      from '../../utils/VXPay';

class Login extends React.Component {

	static onLoginClick(event) {
		event.preventDefault();
		VXPay.openLogin();
	}

	static onSignupClick(event) {
		event.preventDefault();
		VXPay.openSignup();
	}

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="navigation-guest__buttons">
				<div className="navigation-guest__text--teaser">
					{Translations.get('NavbarLabelSignupTeaser')}
				</div>
				<a href="#" onClick={Login.onSignupClick} className="navigation-guest__button--register">
					<i className="navigation-guest__icon--button -icon-user-full" />
					<span className="navigation-guest__text">{Translations.get('NavbarLabelSignup')}</span>
				</a>
				<a href="#" onClick={Login.onLoginClick} className="navigation-guest__button--login">
					<i className="navigation-guest__icon--button -icon-user-full" />
					<span className="navigation-guest__text">{Translations.get('NavbarLabelLogin')}</span>
				</a>
			</div>
		);
	}
}

export default Login;
