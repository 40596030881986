import React                from 'react';
import PropTypes            from 'prop-types';
import {Link}               from 'react-router-dom';
import HistoryStateHelper   from '../../../utils/HistoryStateHelper';
import MobileRoutes         from '../Router/MobileRoutes';
import Flux                 from '../../../flux/Flux';
import Translations         from '../../../utils/Translations';
import { trackNavbarClick } from '../../../utils/GAHelper';
import { generateKey } from '../../../utils/CommonUtils';
import withNavigate from '../../HigherOrderComponents/Utility/withNavigate';

class SecondNavbar extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			shadow: {
				left:  false,
				right: true,
			},
		};

		this.containerRef = null;
		this.wrapperRef   = null;

		this.setContainerRef  = this.setContainerRef.bind(this);
		this.setWrapperRef    = this.setWrapperRef.bind(this);
		this.getItem          = this.getItem.bind(this);
		this.handleScroll     = this.handleScroll.bind(this);
		this.scrollToPosition = this.scrollToPosition.bind(this);
	}

	componentDidMount() {
		const elem = document.getElementById('mobile-content-wrapper');

		if (elem && this.props.isThirdNavbar) {
			elem.classList.add('third-nav');
		}

		// initial scroll
		if (this.wrapperRef) {
			const activeItem = this.wrapperRef.querySelector('.vxsecondnavbar__item--active');
			if (activeItem) {
				this.scrollToPosition(activeItem.offsetLeft, 'instant');
			}
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.items[0].link !== this.props.items[0].link) {
			this.scrollToPosition(0);
		} else {
			if (this.wrapperRef) {
				setTimeout(() => {
					const activeItem = this.wrapperRef.querySelector('.vxsecondnavbar__item--active');
					if (activeItem) {
						this.scrollToPosition(activeItem.offsetLeft);
					}
				}, 2000);
			}
		}
	}

	componentWillUnmount() {
		const elem = document.getElementById('mobile-content-wrapper');

		if (elem && this.props.isThirdNavbar) {
				elem.classList.remove('third-nav');
			}
	}

	handleScroll(ref) {
		if (this.containerRef && ref) {
				const containerX     = this.containerRef.getBoundingClientRect().x;
				const containerWidth = Math.round(this.containerRef.getBoundingClientRect().width);
				const wrapperWidth   = ref.target.getBoundingClientRect().width;
				if (containerX < 0) {
					if (((wrapperWidth - containerWidth) < containerX)) {
						this.setState({shadow: {left: true, right: true}});
					} else {
						this.setState({shadow: {left: true, right: false}});
					}
				} else if (containerX === 0) {
					this.setState({shadow: {left: false, right: true}});
				}
			}
	}
	returnToLastSite(event) {
		if (typeof event === 'object' && event.cancelable) {
			event.preventDefault();
			event.stopPropagation();
		}
		// avoid back navigation to external page
		if (this.userComesFromExternalPage()) {
				if(this.isTopCamsSite()) {
					this.props.navigate(MobileRoutes.getLink(MobileRoutes.CAMS));
				} else {
					this.props.navigate(MobileRoutes.getLink(MobileRoutes.VXMOBILE));
				}
		} else {
			this.props.navigate(-1);
		}
	}

	userComesFromExternalPage(){
		// avoid back navigation to external page when reaching the initial page call in the history
		const value = HistoryStateHelper.hasKey(Flux.Constants.HistoryStateKeys.INITIAL_PAGE);
		return value;
	}

	onClickItem(item, event) {
		event.preventDefault();
		const getParam = new URLSearchParams(window.location.search).get("category");
		const pathname = window.location.pathname;
		let path       = pathname + getParam ? window.location.search.replace(getParam, item.id) : "";

        if (this.props.containerId === 'contests__container') {
			path = item.link;
		}

		if (item.gaTracking) {
			trackNavbarClick(item.gaTracking, item.link);
		}

		if (this.props.parentCallback) {
            this.props.navigate(path, {replace: true});
			this.props.parentCallback(item.id);
		} else if (item.callback) {
            if (this.props.navigate && item.link) {
                this.props.navigate(item.link);
			}
			item.callback(item.id);
		} else {
			this.props.navigate(item.link);
		}
		const left = event.target.offsetLeft;
		this.scrollToPosition(left);

	}

	scrollToPosition(left, behavior = 'smooth') {
		left = left - 30;
		this.wrapperRef.scrollTo({
			left,
			behavior,
		});
	}

	getItem(item, idx) {
		const url = item.link || '#';
		const text = <span>{item.text}</span>;
		return (
			<li key={generateKey('vxsecondnavbar-item', idx)}>
				<Link to={url}
				      className={"vxsecondnavbar__item" + (item.isActive ? " vxsecondnavbar__item--active" : "") + (this.props.isHeadline ? " vxsecondnavbar__item--headline" : "")}
				      onClick={(e) => this.onClickItem(item, e)}
				>
					{(this.props.isHeadline && this.isTopCamsSite()) &&
					<div
						className=' vxsecondnavbar__item--headline__arrow-fixed-left'
						onClick={(e) => this.returnToLastSite(e)}
					>
						<i className="icon -icon-single-arrow-left-line"></i>
					</div>
					}
					{item.headlineType && item.isActive && React.createElement(item.headlineType, null, text)}
					{(!item.headlineType || !item.isActive) && text} {item.isNew &&
				<span className="vxsecondnavbar__item__badge vxsecondnavbar__item__badge--new">{Translations.get('BadgeNew')}</span>}{typeof item.quantity === 'number' &&
				<span className="vxsecondnavbar__item-quantity">&nbsp;({item.quantity})</span>}
				</Link>
			</li>
		);
	}

	setContainerRef(ref) {
		if (ref) {
			this.containerRef = ref;
		}
	}

	setWrapperRef(ref) {
		if (ref) {
			this.wrapperRef = ref;
		}
	}

	isTopCamsSite(){
		const currentPath = window.location.pathname;
		const topCamsCategory = MobileRoutes.getLink(MobileRoutes.CATEGORIES_TOP_CAMS);
		return currentPath.includes(topCamsCategory);
	}

	render() {
		const {items, isHeadline, isThirdNavbar, useId, hideSecondNavigation, isRelative, isSticky, noHeight, hideSecondNavHeadline} = this.props;
		const additionalStyle                                                                                                        = {gridTemplateColumns: `repeat(${items.length}, 1fr`};

		return (
			<div className={"vxsecondnavbar" + (isThirdNavbar ? " vxsecondnavbar--third-navbar" : "") + (this.isTopCamsSite() ? " vxsecondnavbar--topcams" : "") + (hideSecondNavigation ? " vxsecondnavbar--hide-second-navbar" : "") + (isSticky ? " vxsecondnavbar--sticky" : "") + (noHeight ? " vxsecondnavbar--no-height" : "")}>
				{!isRelative && <div className="vxsecondnavbar__placeholder"></div>}
				<div className={"vxsecondnavbar__fixed" + (isHeadline ? " vxsecondnavbar--headline" : "") + (hideSecondNavHeadline ? " vxsecondnavbar--headline-hide-animation" : "") + (isRelative ? " vxsecondnavbar--relative" : "")}
				     id={useId ? "second-navbar-anchor" : ""}
				>
					{this.state.shadow.left && <div className="vxsecondnavbar__shadow vxsecondnavbar__shadow--left"></div>}
					<div className="vxsecondnavbar__wrapper" ref={(ref) => this.setWrapperRef(ref)} onScroll={(ref) => this.handleScroll(ref)}>
						<ul className={"vxsecondnavbar__container" + (isHeadline ? " vxsecondnavbar__container--headline" : "")}
						    ref={(ref) => this.setContainerRef(ref)}
						    style={additionalStyle}
						>
							{items.map((item, idx) => this.getItem(item, idx))}
						</ul>
					</div>
					{this.state.shadow.right && <div className="vxsecondnavbar__shadow vxsecondnavbar__shadow--right"></div>}
				</div>
			</div>);
	}
}

SecondNavbar.propTypes = {
	items:                 PropTypes.arrayOf(
		PropTypes.shape({
			text:     PropTypes.string,
			isActive: PropTypes.bool,
			link:     PropTypes.string,
		})),
	isHeadline:            PropTypes.bool,
	isThirdNavbar:         PropTypes.bool,
	useId:                 PropTypes.bool,
	hideSecondNavigation:  PropTypes.bool,
	isRelative:            PropTypes.bool,
	isSticky:              PropTypes.bool,
	noHeight:              PropTypes.bool,
	hideSecondNavHeadline: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	id:                    PropTypes.string,
	parentCallback:        PropTypes.func,
	containerId:           PropTypes.string,
    navigate:              PropTypes.func,
};

SecondNavbar.defaultProps = {
	isHeadline:            false,
	isThirdNavbar:         false,
	useId:                 false,
	hideSecondNavigation:  false,
	isRelative:            false,
	isSticky:              false,
	noHeight:              false,
	hideSecondNavHeadline: false,
	id:                    '',
};

export default withNavigate(SecondNavbar);

