/* global VXConfig */
import React                      from 'react';
import {NavLink}                  from 'react-router-dom';
import MobileRoutes               from '../Router/MobileRoutes';
import Flux                       from '../../../flux/Flux';
import {toggleMenu}               from './Helper';
import PropTypes                  from 'prop-types';
import PhoneAccess                from '../../PhoneAccess';
import Translations               from '../../../utils/Translations';
import {generateKey, isStartPage} from '../../../utils/CommonUtils';
import withNavigate               from '../../HigherOrderComponents/Utility/withNavigate';

class Header extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
    }
	onSearchClick(event) {
		event.preventDefault();
		toggleMenu(Flux.Constants.VXMobile.Menus.SEARCH);
	}

	onMenuClick(event) {
		event.preventDefault();
		toggleMenu(Flux.Constants.VXMobile.Menus.NAVI);
	}

	onNotificationClick(event) {
		event.preventDefault();
		toggleMenu(Flux.Constants.VXMobile.Menus.NOTIFICATIONS);
	}

	onLogoClick() {
		Flux.ProSearch.loadPage(1);
		Flux.VXMobile.setScrollToOnMount(MobileRoutes.CAMS, Flux.Constants.ScrollAnchors.ANCHOR);
	}

	onBackButtonClick(event) {
		event.preventDefault();
		this.props.navigate(-1);
	}

	shouldShowPhoneAccess() {
		return Flux.Browser.isSexole() || VXConfig.language === 'es';
	}

	render() {
		return [
			<div key={generateKey('vxheader', 0)} className="vxheader__placeholder"></div>,
			isStartPage() ? <h1 key={generateKey('vxheader-h1', 1)}>{Flux.Browser.isSexole() ? Translations.get('seo_headlines.footer-h1_sexole') : Translations.get('seo_headlines.footer-h1')}</h1> : '',
			<nav key={generateKey('vxheader-nav', 2)} className="vxheader">
				<div className="vxheader__item-group">
					<NavLink to={MobileRoutes.getLink(MobileRoutes.VXMOBILE)} onClick={this.onLogoClick} className="vxheader__item vxheader__item--logo">
						<img src={Flux.Browser.isSexole() ? "/assets/img/sexole/sexole_x_visit-x_logo.svg" : "/assets/img/logo2.svg"} alt="VISIT-X"/>
					</NavLink>
				</div>
				<div className="vxheader__item-group vxheader__item-group--right">
					{this.shouldShowPhoneAccess() && <PhoneAccess linkClass="vxheader__item" iconClass="vxheader__icon" />}
					{this.props.isLoggedIn && <NavLink to="#" className={"vxheader__item"} onClick={this.onNotificationClick}>
						{this.props.countNotifications > 0 && <span className={"vxheader__item-badge" + (this.props.menus.notifications ? ' vxheader__item-badge--active' : '')}>{this.props.countNotifications}</span>}
						<i className={"vxheader__icon icon -icon-bell-full" + (this.props.menus.notifications ? ' vxheader__icon--active' : '')}></i>
					</NavLink>}
					<NavLink to="#" className={"vxheader__item h-cursor-default"} onClick={this.onSearchClick}>
						<i className={"vxheader__icon icon -icon-search-full"}></i>
					</NavLink>
					<NavLink to="#" className={"vxheader__item h-cursor-default"} onClick={this.onMenuClick}>
						<i className={"vxheader__icon icon -icon-hamburger-line"}></i>
					</NavLink>
				</div>
			</nav>,
		];
	}
}

Header.propTypes = {
	isLoggedIn:         PropTypes.bool,
	menus:              PropTypes.object.isRequired,
	countNotifications: PropTypes.number,
    navigate:           PropTypes.func,
};

Header.defaultProps = {
	countNotifications: 0,
};

export default withNavigate(Header);
