/* globals VXConfig */

import React                  from 'react';
import PropTypes              from 'prop-types';
import Translations           from "../../../utils/Translations";
import VXButton               from "../../SimpleElements/VXButton/VXButton";
import { COLOR_TYPE_SPECIAL } from "../../SimpleElements/VXButton/ButtonHelper";
import { VXButtonLineConfig } from "../../SimpleElements/VXButton/VXButtonConfig";
import STYLES                 from "../../SimpleElements/VXButton/EnumVXButton";
import VideoTile              from '../../Grid2/Tiles/VideoTile';
import MediathekTile          from '../../Grid2/Tiles/MediathekTile';
import BlogTile               from '../../Grid2/Tiles/BlogTile';
import Grid2                  from '../../Grid2/Grid2';
import Flux                   from '../../../flux/Flux';
import SecondSearchContext    from '../../../context/SecondSearchContext';
import withNavigate           from '../../HigherOrderComponents/Utility/withNavigate';

class NavbarSearchResult extends React.Component {
	constructor(props) {
		super(props);
		this.getActorsContainer = this.getActorsContainer.bind(this);
		this.showAllResults     = this.showAllResults.bind(this);
        this.openLink           = this.openLink.bind(this);
	}

	openLink(link, storeResult = false) {
		if (storeResult) {
			Flux.GlobalSearch.storeSearchRequest(this.props.searchPattern);
		}
        Flux.VXMobile.closeMenu(Flux.Constants.VXMobile.Menus.SEARCH);
		this.props.navigate(link);
	}

	getGrid(result) {
		let tileClass;
		switch (result.type) {
			case Flux.Constants.SearchResults.Categories.videos.name:
				tileClass = VideoTile;
				break;
			case Flux.Constants.SearchResults.Categories.mediathek.name:
				tileClass = MediathekTile;
				break;
			case Flux.Constants.SearchResults.Categories.blog.name:
				tileClass = BlogTile;
				break;
			default:
				tileClass = VideoTile;
		}

		if (result.items && result.items.length > 0) {
			return (<Grid2
				tileData={result.items}
				defaultTileClass={tileClass}
				defaultTileConfig={{ badges: { textBadges: { show: { price: false, new: false } } } }}
				columns={{ xs: 2 }}
				tileClasses={[]}
				tileConfigs={[]}
				tileSizes={[]}
			/>);
		}

		return null;
	}

	getSearchResultItem(text) {
		const term    = this.props.searchPattern.toLowerCase().trim();
		const textLow = text.toLowerCase();
		if (textLow.search(term) === -1) {
			return (
				<div>{text}</div>
			);
		}

		const before  = text.slice(0, (textLow.search(term)));
		const bold    = text.slice(textLow.search(term), (textLow.search(term) + term.length));
		const after   = text.slice(before.length + bold.length, text.length);

		return (
			<div>{before}<b>{bold}</b>{after}</div>
		);
	}

	getSuggestionContainerNull(showProposals) {
		const suggestions = this.props.suggestions;
		if (suggestions.length > 0 && !showProposals) {
			return (
				<div className="navigation-search__proposal navigation-search--padding">
					<div className={"navigation-search__proposal-headline"}>
						{Translations.get('GlobalSearchSuggestionsQuestion')}
					</div>
					{ suggestions.map((suggestion, index) => (
						<div onClick={() => this.openLink(suggestion.targetUrl)} className={"navigation-search__proposal-item"} key={index}>
							{suggestion.name}
						</div>
					))}
				</div>
			);
		}
		return null;
	}

	getLastSearchRequestNull(showProposals) {
		const lastSearchRequests = this.props.lastSearchRequests;
		if (lastSearchRequests.length > 0 && !showProposals) {
			return (
				<div className={"navigation-search__proposal navigation-search--padding"}>
					<div className={"navigation-search__proposal-headline"}>
						<span className="icon -icon-round-time-line global_search-dropdown-headline-icon"></span>
						<span >
							{Translations.get('GlobalSearchPreviousQuestion')}
						</span>
					</div>
					{ lastSearchRequests.map((lastSearchRequest, index) => (
						<div onClick={() => {this.props.isResultPageSearch ? this.props.setSearchPattern(lastSearchRequest, false) : this.props.setSearchPattern(lastSearchRequest, true);}} className={"navigation-search__proposal-item"} key={index}>
							{lastSearchRequest}
						</div>
					))}
				</div>
			);
		}
		return null;
	}

	getNoResultContainer() {
		return (<div className={"navigation-search__no-result"}>
			<div className={"navigation-search__no-result-headline"}>
				{Translations.get('SearchForTagsNoResult')}
			</div>
			<div className={"navigation-search__no-result-subline"}>
				{Translations.get('SearchForTagsNoResultAdjust')}
			</div>
		</div>);
	}

	getProposalContainer(proposals, showProposals) {
		if (proposals.length > 0 && showProposals) {
			return (
				<div className={"navigation-search__proposal navigation-search--padding"}>
					<div className={"navigation-search__proposal-headline"}>
						{Translations.get('GlobalSearchProposals')}
					</div>
					{ proposals.map((proposal, index) => (
						<div onClick={() => {this.props.isResultPageSearch ? this.props.setSearchPattern(proposal, false) : this.props.setSearchPattern(proposal, true);}} className={"navigation-search__proposal-item"} key={index}>
							{this.getSearchResultItem(proposal)}
						</div>
					))}
				</div>
			);
		}
		return null;
	}

	getActorsContainer(allActors) {
		const maxActorsShown = 3;
		if (allActors.count > 0) {
			const actors = allActors.items.slice(0, maxActorsShown);
			return (
				<SecondSearchContext.Consumer>
					{context => {
						const action = () => {
							context.updateTopSearchShowed(() => {
								this.openLink(allActors.link, true);
							}, false, true);
						};
						return (
							<div>
								<div className={"navigation-search__results-item"}>
									<span>{Translations.get('CollectionSearchResultsActors')}
	                           <span className={"navigation-search__results-headline--count"}>{allActors.count}</span>
									</span>
									<div onClick={action} className={"navigation-search__results-headline--link"}>{Translations.get('ShowAll')}</div>
								</div>
								{ actors.map((actor, index) => (
									<div onClick={() => this.openLink(actor.link)} className={"navigation-search__results-item--actor"} key={index}>
										<span className={"global_search-dropdown-actor"}>
											<span style={{ backgroundImage: 'url(' + actor.imageUrl + ')' }} className={"global_search-dropdown-actor-image" + (actor.online ? ' -online' : '') + (actor.mobileVideoCall ? ' -mobile-videocall' : '')}></span>
											<div className={"global_search-dropdown-actor-info-container"}>
												{this.getSearchResultItem(actor.name)}
	                                 <span className={"global_search-dropdown-actor-online" + (actor.online ? ' -online' : '')}>
													{actor.online ? 'Online' : 'Offline'}
												</span>
											</div>
										</span>
									</div>
								))}
							</div>);
					}
					}
				</SecondSearchContext.Consumer>);

		}
		return null;
	}
	checkEmpty(value){
		return !(value !== null && typeof value !== 'undefined');
	}

	getContainer(title, result) {
		const { count, link } = result;
		if (count > 0) {
			return (
				<SecondSearchContext.Consumer>
					{context => {
						const action = () => {
							context.updateTopSearchShowed(() => {
								this.openLink(link, true);
							}, false, true);
						};
						return (
							<div className={"navigation-search__results-item"}>
								<span>
									{title}
	                        <span className={"navigation-search__results-headline--count"}>{count}</span>
								</span>
								<div onClick={action} className={"navigation-search__results-headline--link"}>{Translations.get('ShowAll')}</div>
							</div>
						);
					}
					}
				</SecondSearchContext.Consumer>
			);
		}
	}

	showAllResults(){
		if(this.props.actorsSearchResult.count !== 0){
			this.openLink(this.props.actorsSearchResult.link);
		} else if (this.props.videosSearchResult.count !== 0){
			this.openLink(this.props.videosSearchResult.link);
		} else if (this.props.mediathekSearchResult.count !== 0){
			this.openLink(this.props.mediathekSearchResult.link);
		} else if (this.props.blogSearchResult.count !== 0){
			this.openLink(this.props.blogSearchResult.link);
		}
	}

	render() {
		const proposals     = this.props.proposals;
		const actors        = this.props.actorsSearchResult;
		const videos        = this.props.videosSearchResult;
		const mediathek     = this.props.mediathekSearchResult;
		const blog          = this.props.blogSearchResult;
		const showProposals = (this.props.searchPattern.length > (this.props.minSearchPatternLength - 1)) && this.props.resultAvailable;
		const resultClass   = "navigation-search__results-preview navigation-search--padding";

		if(this.checkEmpty(actors) || this.checkEmpty(videos) || this.checkEmpty(mediathek) || this.checkEmpty(blog)){
			return <div></div>;
		}

		const hasResult     = (actors.count > 0 || videos.count > 0 || mediathek.count > 0 || blog.count > 0);

		const suggestionsContainer = this.getSuggestionContainerNull(showProposals);
		const lastSearchRequestsContainer = this.getLastSearchRequestNull(showProposals);

		// default show suggestions / last search
		if (lastSearchRequestsContainer || suggestionsContainer && !showProposals) {
			return (<div className={"navigation-search__results"}>
				{suggestionsContainer}
				{lastSearchRequestsContainer}
			</div>);
			// show no result text if nothing was found
		} else if (showProposals && (proposals.length === 0) && !hasResult && this.props.resultAvailable) {
			const noResultContainer = this.getNoResultContainer();
			return noResultContainer;
		} else if (!showProposals) {
			return null;
		}


		const proposalContainer = this.getProposalContainer(proposals, showProposals);
		// show only proposals while research
		if (this.props.showOnlyProposals) {
			return (<div className={"navigation-search__results"}>
				{proposalContainer}
			</div>);
		}

		const isSexole           = Flux.Browser.isSexole();
		const isSpanish          = VXConfig.language === Flux.Constants.Languages.ES;

		const actorsContainer    = this.getActorsContainer(actors);
		const videosContainer    = this.getContainer('Videos', videos);
		const mediathekContainer = (isSexole || isSpanish) ? null : this.getContainer('TV', mediathek);
		const blogContainer      = (isSexole || isSpanish) ? null : this.getContainer('Blog', blog);

		return (
			<SecondSearchContext.Consumer>
				{context => {
					const action = () => {
						context.updateTopSearchShowed(() => {
							this.showAllResults();
						}, false, true);
					};
					return (
						<div className="navigation-search__results ">
							{proposalContainer}
							<div className={resultClass}>
								{actorsContainer}
								{videosContainer}
								{mediathekContainer}
								{blogContainer}
							</div>
							<div className={"navigation-search__preview-button"}>
								<VXButton color={COLOR_TYPE_SPECIAL}
								          line1={[new VXButtonLineConfig(Translations.get('GlobalSearchPreviousAll'))]}
								          onClick={action}
								          size={STYLES.SIZE.SMALL}
								/>
							</div>
						</div>
					);
				}
				}
			</SecondSearchContext.Consumer>
		);


	}
}

NavbarSearchResult.propTypes = {
	suggestions:            PropTypes.array,
	proposals:              PropTypes.array,
	resultAvailable:        PropTypes.bool,
	searchPattern:          PropTypes.string,
	lastSearchRequests:     PropTypes.array,
	setSearchPattern:       PropTypes.func,
	minSearchPatternLength: PropTypes.number,
	showOnlyProposals:      PropTypes.bool,
	actorsSearchResult:     PropTypes.shape({
		count: PropTypes.number,
		items: PropTypes.array,
		link:  PropTypes.string,
	}),
	blogSearchResult:       PropTypes.shape({
		count: PropTypes.number,
		link:  PropTypes.string,
		items: PropTypes.array,
	}),
	mediathekSearchResult:  PropTypes.shape({
		count: PropTypes.number,
		link:  PropTypes.string,
		items: PropTypes.array,
	}),
	videosSearchResult:     PropTypes.shape({
		count: PropTypes.number,
		link:  PropTypes.string,
		items: PropTypes.array,
	}),
	isResultPageSearch: PropTypes.string,
    navigate:           PropTypes.func,
};

NavbarSearchResult.defaultProps = {

};

export default withNavigate(NavbarSearchResult);

