/* globals VXConfig */

import React, {Suspense}                       from 'react';
import Flux                                    from './../../flux/Flux';
import MobileRoutes                            from './Router/MobileRoutes';
import Translations                            from './../../utils/Translations';
import Survey                                  from './../Survey/Survey';
import LogoLoader                              from './../Loader/LogoLoader';
import GlobalEventHandler                      from './../../utils/GlobalEventHandler';
import HistoryStateHelper                      from './../../utils/HistoryStateHelper';
import RouterHelper                            from './../../utils/RouterHelper';
import {DefaultRouter}                         from './Router/RouterContainer';
import UrlBuilder                              from "../../utils/UrlBuilder";
import Navigation                              from './Navigation/Index';
import SecondSearchContext                     from '../../context/SecondSearchContext';
import {gaHelper, getCookie, shouldShowEsAVSModal}       from "../../utils/CommonUtils";
import withRouter                              from '../HigherOrderComponents/Utility/withRouter.js';
import PropTypes                               from 'prop-types';
import {BonusCodeInfoBannerWithNotPayingGuard}           from '../BonusCodeInfoBanner';
import {ModalBoxEnum, openModalBox, openOnboardingModal} from '../ModalBox/ModalBoxFactory';
import FloatingNotifications                             from "../Notifications/FloatingNotifications.js";
import FifteenMinutesPreviewCountdown          from '../FifteenMinutesPreview/FifteenMinutesPreviewCountdown.js';
import StatusBarWrapper                        from '../StatusBar/StatusBarWrapper.js';

const WebPushSubscribe               = React.lazy(() => import('./../../components/WebPushSubscribe'));
const VXMessengerChat                = React.lazy(() => import('../VXMessenger/VXMessengerChat'));
const Conversion                     = React.lazy(() => import('./../Conversion/Conversion'));
const ConversionBanner               = React.lazy(() => import("../Banner/ConversionBanner"));
const ModalWelcome                   = React.lazy(() => import('../ModalBox/ModalWelcome'));
const ModalBoxTrialConversionChat    = React.lazy(() => import('../ModalBox/ModalBoxTrialConversionChat'));
const ModalBoxWelcomeBanner          = React.lazy(() => import('./../ModalBox/ModalBoxWelcomeBanner'));
const PrivateTicketShowInvitationBox = React.lazy(() => import("../VXMessenger/PrivateTicketShowInvitationBox"));
const DataProtectionBar              = React.lazy(() => import('./../DataProtection/DataProtectionBar'));


let showLoaderTimeout, fallbackLoaderTimeout;

function isShowFifteenMinutesPreviewCountdown() {
    return VXConfig.guest && VXConfig.guest.esFreePreview && VXConfig.guest.esFreePreview.timerEnd;
}

class VXMobile extends React.Component {

	static checkRestrictedUser(targetUrl) {
		if (Flux.Guest.isLoggedIn() && Flux.Guest.isRestricted() && targetUrl !== MobileRoutes.getLink(MobileRoutes.RESTRICTED)) {
			this.props.navigate(MobileRoutes.getLink(MobileRoutes.RESTRICTED));
		}
	}

	static checkForcePayment(targetUrl) {
		if (Flux.Guest.isLoggedIn() && Flux.Guest.isForcePayment()) {
			window.location.href = targetUrl;
		}
	}

	static isCookieSet() {
		return getCookie(Flux.Constants.CookieNames.COOKIEBANNER_ACCEPTED) === '1';
	}

	static closeNavbarElements(target) {
		Flux.VXMobile.closeMenu(Flux.Constants.VXMobile.Menus.NAVI);
		Flux.VXMobile.closeMenu(Flux.Constants.VXMobile.Menus.MY_VISIT_X);
		Flux.VXMobile.closeMenu(Flux.Constants.VXMobile.Menus.NOTIFICATIONS);

		if (target !== MobileRoutes.getLink(MobileRoutes.CAMS)) {
			Flux.VXMobile.closeMenu(Flux.Constants.VXMobile.Menus.SEARCH);
		}
	}

	static getNotificationsCount(notifications) {
		const hiddenTypes = Flux.Constants.VXMobile.HiddenNotificationTypes;
		let unseenCount   = 0;
		for (let i = 0; i < notifications.length; i++) {
			if (notifications[i].unseen && hiddenTypes.indexOf(notifications[i].type) < 0) {
				unseenCount++;
			}
		}

		return unseenCount;
	}

	static onDisableBodyScrollChange() {
		if (Flux.VXMobile.isBodyScrollDisabled()) {
			document.querySelector('html').classList.add('hide-overflow');
		} else {
			document.querySelector('html').classList.remove('hide-overflow');
		}
	}

	constructor(props) {
		super(props);
		const notifications = Flux.Notification.getNavbarNotifications();

		this.state = {
			countFavorites:                     Flux.Favorite.getOnlineCount(),
			countNotifications:                 VXMobile.getNotificationsCount(notifications),
			countUnreadMessages:                Flux.Messenger.getUnreadMessagesCount(),
			hideOfflineIcon:                    false,
			isLandscape:                        Flux.Browser.isLandscape(),
			isNavbarHidden:                     Flux.VXMobile.isNavbarHidden(),
			isOnline:                           Flux.Browser.isOnline(),
			loaderProps:                        {},
			notifications:                      notifications,
			showDataProtectionBar:              Flux.Guest.isDataProtectionShown(),
			showLoader:                         this.props.location.pathname === MobileRoutes.getLink(MobileRoutes.VXMOBILE),
			showReloadFallback:                 false,
			topSearchShowed:                    true,
			secondSearchShowed:                 false,
			isLoggedIn:                         Flux.Guest.isLoggedIn(),
			menus:                              {},
			privateTicketShowInvitation:        null,
            showSpace:                          this.isShowSpace(),
            showFifteenMinutesPreviewCountdown: isShowFifteenMinutesPreviewCountdown(),
		};

		this.mobileContentWrapperRef = null;

		this.checkNavbarState                   = this.checkNavbarState.bind(this);
		this.getGuestCountTotal                 = this.getGuestCountTotal.bind(this);
		this.navigateToMessenger                = this.navigateToMessenger.bind(this);
		this.onChannelSelected                  = this.onChannelSelected.bind(this);
		this.onHideNavbarChange                 = this.onHideNavbarChange.bind(this);
		this.onLoaderToggle                     = this.onLoaderToggle.bind(this);
		this.onMessageChange                    = this.onMessageChange.bind(this);
		this.onOnlineChange                     = this.onOnlineChange.bind(this);
		this.onOfflineImageError                = this.onOfflineImageError.bind(this);
		this.onRouterListenBefore               = this.onRouterListenBefore.bind(this);
		this.onWindowResize                     = this.onWindowResize.bind(this);
		this.updateFavorites                    = this.updateFavorites.bind(this);
		this.updateNotifications                = this.updateNotifications.bind(this);
		this.updateUnreadMessages               = this.updateUnreadMessages.bind(this);
		this.setMobileContentWrapperRef         = this.setMobileContentWrapperRef.bind(this);
		this.setShowSpace                       = this.setShowSpace.bind(this);
		this.updateTopSearchShowed              = this.updateTopSearchShowed.bind(this);
		this.parentCallback                     = this.parentCallback.bind(this);
		this.onPrivateTicketShowInvitation      = this.onPrivateTicketShowInvitation.bind(this);
        this.hideFifteenMinutesPreviewCountdown = this.hideFifteenMinutesPreviewCountdown.bind(this);
        this.navigateTo = this.navigateTo.bind(this);
	}

	componentDidMount() {
		Flux.Browser.addOnlineChangeListener(this.onOnlineChange);
		Flux.Browser.addWindowResizeListener(this.onWindowResize);
		Flux.Favorite.addActorsChangeListener(this.updateFavorites);
		Flux.Messenger.addChannelSelectedListener(this.onChannelSelected);
		Flux.Messenger.addNavbarMessageChangeListener(this.onMessageChange);
		Flux.Notification.addNavbarNotificationsChangeListener(this.updateNotifications);
		Flux.NewMessenger.addPrivateTicketShowInvitationListener(this.onPrivateTicketShowInvitation);

		Flux.VXMobile.addDisableBodyScrollChangeListener(VXMobile.onDisableBodyScrollChange);
		Flux.VXMobile.addHideNavbarChangeListener(this.onHideNavbarChange);

        Flux.VXMobile.addNavigateToListener(this.navigateTo);

		GlobalEventHandler.addListener(GlobalEventHandler.LOADER_TOGGLE, this.onLoaderToggle);

		//this.props.history.block(this.onRouterListenBefore);
		VXMobile.checkRestrictedUser(this.props.location.pathname);

		// set initial page call to be able to change behavior of back buttons in order to avoid bounces
		HistoryStateHelper.setValue(Flux.Constants.HistoryStateKeys.INITIAL_PAGE, true);

		// Needed for A/B-Test AVS
		if (!Flux.Guest.hasAvs() && window.VXConfig.forceAVSModal) {
			Flux.VXMobile.disableBodyScroll('avsBlocker');
		}

        if (VXConfig.guest && VXConfig.guest.showPhotoAlbumRemovalModal) {
            openModalBox(ModalBoxEnum.REMOVE_PHOTOS, {overCookie: true, preventScrolling: true});
        }

		if (VXConfig.loginBonus) {
			openModalBox(ModalBoxEnum.FIVE_DAYS_BONUS, {overCookie: true});
		}

		if (shouldShowEsAVSModal()) {
			openModalBox(ModalBoxEnum.ES_AVS, {overCookie: true});
		}

        if (VXConfig.guest && VXConfig.guest.esFreePreview && VXConfig.guest.esFreePreview.showModal) {
            openModalBox(ModalBoxEnum.FIFTEEN_MINUTES_PREVIEW, {overCookie: true});
        }

        if (VXConfig.guest && VXConfig.guest.coinMigrationModal) {
            openModalBox(ModalBoxEnum.VXCOIN_INFO, {overCookie: true});
        }
	}

	UNSAFE_componentWillReceiveProps() {
		// assume props will only change on routeChange
	}

	componentWillUnmount() {
		Flux.Browser.removeOnlineChangeListener(this.onOnlineChange);
		Flux.Browser.removeWindowResizeListener(this.onWindowResize);
		Flux.Favorite.removeActorsChangeListener(this.updateFavorites);
		Flux.Messenger.removeChannelSelectedListener(this.onChannelSelected);
		Flux.Messenger.removeNavbarMessageChangeListener(this.onMessageChange);
		Flux.Notification.removeNavbarNotificationsChangeListener(this.updateNotifications);
		Flux.NewMessenger.removePrivateTicketShowInvitationListener(this.onPrivateTicketShowInvitation);

		Flux.VXMobile.removeDisableBodyScrollChangeListener(VXMobile.onDisableBodyScrollChange);
		Flux.VXMobile.removeHideNavbarChangeListener(this.onHideNavbarChange);

        Flux.VXMobile.removeNavigateToListener(this.navigateTo);

		GlobalEventHandler.removeListener(GlobalEventHandler.LOADER_TOGGLE, this.onLoaderToggle);
	}

    logPageView() {
        if (!Flux.VXMobile.isDevServer()){
            gaHelper('set', 'page', window.location.pathname + window.location.search);
            gaHelper('send', 'pageview');
        }
    }

	onPrivateTicketShowInvitation(invitation) {
		this.setState({
			privateTicketShowInvitation: invitation,
		});
	}

	updateTopSearchShowed(callback, topSearchShowed, secondSearchShowed) {
		this.setState({
			topSearchShowed:    topSearchShowed,
			secondSearchShowed: secondSearchShowed,
		}, () => callback(this.state));
	}

	setMobileContentWrapperRef(ref) {
		if (ref) {
			this.mobileContentWrapperRef = ref;
		}
	}

	checkNavbarState(targetUrl) {
		// store current navbar state
		Flux.VXMobile.storeNavbarState(this.props.location.pathname.toLowerCase());

		// restore previous store when BackButton is clicked
		if (Flux.VXMobile.shouldRestoreNavbarState()) {
			Flux.VXMobile.restoreNavbarState(targetUrl);
		} else {
			Flux.VXMobile.showNavbar();
		}

		Flux.VXMobile.setRestoreNavbarState(false);
	}

	getGuestCountTotal() {
		return this.state.countFavorites + this.state.countNotifications + this.state.countUnreadMessages;
	}

	onMessageChange() {
		this.updateUnreadMessages();
	}

	onChannelSelected() {
		const channel = Flux.Messenger.getSelectedChannel();
		if (channel) {
			this.updateUnreadMessages();
			this.navigateToMessenger();
		}
	}

	onOnlineChange() {
		this.setState({
			isOnline: Flux.Browser.isOnline(),
		});
	}

	onRouterListenBefore(target) {
		this.checkNavbarState(target.pathname);
		VXMobile.closeNavbarElements(target.pathname);
		VXMobile.checkRestrictedUser(target.pathname);
		VXMobile.checkForcePayment(target.pathname);
		this.onLoaderToggle(false);
	}

    navigateTo(url, replace) {
        if (replace) {
            this.props.navigate(url, {replace: true});
        } else {
            this.props.navigate(url);
        }
    }

	navigateToMessenger() {
		if (this.props.location.pathname.toLowerCase().indexOf('/' + MobileRoutes.MESSENGER.toLowerCase()) === -1) {
			Flux.Messenger.setRestorePreviousUrl(true);
			this.props.navigate(MobileRoutes.getLink(MobileRoutes.MESSENGER));
		}
	}

	updateUnreadMessages() {
		this.setState({
			countUnreadMessages: Flux.Messenger.getUnreadMessagesCount(),
		});
	}

	updateFavorites() {
		this.setState({
			countFavorites: Flux.Favorite.getOnlineCount(),
		});
	}

	updateNotifications() {
		const notifications = Flux.Notification.getNavbarNotifications();
		this.setState({
			countNotifications: VXMobile.getNotificationsCount(notifications),
			notifications:      notifications,
		});
	}

	onHideNavbarChange(callback) {
		if (typeof callback !== 'function') {
			callback = () => {
			};
		}

		if (this.state.isNavbarHidden !== Flux.VXMobile.isNavbarHidden()) {
			this.setState({
				isNavbarHidden: Flux.VXMobile.isNavbarHidden(),
			}, callback);
		}
	}

	onOfflineImageError() {
		this.setState({
			hideOfflineIcon: true,
		});
	}

	onLoaderToggle(visible, loaderProps = {}) {
		if (visible) {
			if (!showLoaderTimeout) {
				showLoaderTimeout = window.setTimeout(() => {
					this.setState({
						showLoader: visible,
						loaderProps,
					}, () => {
						// fallback for timeouts > X seconds
						fallbackLoaderTimeout = window.setTimeout(() => {
							Flux.Tracker.track('network', 'timeout', {
								pathname: this.props.location.pathname,
							});

							this.setState({
								showReloadFallback: true,
							});

							if (fallbackLoaderTimeout) {
								window.clearTimeout(fallbackLoaderTimeout);
								fallbackLoaderTimeout = null;
							}
						}, 30 * 1000);
					});

					window.clearTimeout(showLoaderTimeout);
					showLoaderTimeout = null;
					showLoaderTimeout = null;
				}, 500);
			}
		} else {
			if (showLoaderTimeout) {
				window.clearTimeout(showLoaderTimeout);
				showLoaderTimeout = null;
			}
			if (fallbackLoaderTimeout) {
				window.clearTimeout(fallbackLoaderTimeout);
				fallbackLoaderTimeout = null;
			}
			this.setState({
				showLoader:         visible,
				showReloadFallback: false,
				loaderProps:        {},
			});
		}
	}

	onWindowResize() {
		if (this.state.isLandscape !== Flux.Browser.isLandscape()) {
			this.setState({
				isLandscape: Flux.Browser.isLandscape(),
			});
		}
	}

    isShowSpace() {
        return isShowFifteenMinutesPreviewCountdown();
    }

    hideFifteenMinutesPreviewCountdown() {
        this.setState({showSpace: false, showFifteenMinutesPreviewCountdown: false});
    }

	setShowSpace(showSpace = false) {
		this.setState({showSpace});
	}

	parentCallback(menus) {
		this.setState({
			menus: menus,
		});
	}

	render() {
		const showLoginButtons = !Flux.Guest.isLoggedIn()
			&& !Flux.Guest.isAnonymousVoicecall()
			&& (
				RouterHelper.isCamsRoute(this.props.location.pathname) ||
				RouterHelper.isNewHomepage(this.props.location.pathname)
			);
		let rootClasses        = 'vxmobile-root -full-height ';
		if (this.state.isLandscape) {
			rootClasses += ' vxmobile-landscape';
		}
		if (this.state.isNavbarHidden) {
			rootClasses += ' vxmobile-nonav';
		}
		if (!this.state.isOnline) {
			rootClasses += ' vxmobile-offline';
		}
		if (showLoginButtons) {
			rootClasses += ' vxmobile-logged-out';
		}
		if (this.state.showFifteenMinutesPreviewCountdown) {
			rootClasses += ' vxmobile-show-space';
		}

		let conversionBanner                   = null;
		const guestIsEligibleForConversionChat = Flux.Guest.isEligibleForConversionChat();
		if (guestIsEligibleForConversionChat) {
			conversionBanner = <ConversionBanner conversionUrl={UrlBuilder.buildFreeChatRoute()} isMobile={true} />;
		}

		const showNavbarLight = VXConfig.isEntryPage && VXConfig.entryPageId !== Flux.Constants.EntryPage.Ids.ADWORDS_STARTPAGE;

		const navigation = <Navigation showLoginButtons={showLoginButtons}
		                               notifications={this.state.notifications}
		                               currentPath={this.props.location.pathname}
		                               countUnreadMessages={this.state.countUnreadMessages}
		                               countFavorites={this.state.countFavorites}
		                               isNavbarHidden={this.state.isNavbarHidden}
		                               showNavbarLight={showNavbarLight}
		                               showLoader={this.state.showLoader}
		                               mobileContentWrapperRef={this.mobileContentWrapperRef}
		                               showSpace={this.state.showSpace}
		                               parentCallback={this.parentCallback}
		/>;

		let welcomeBanner;
		if (window.VXConfig.showWelcomeBanner && !Flux.VXMobile.isEntryPage()) {
			welcomeBanner = (
				<ModalBoxWelcomeBanner isMobile={true} />
			);
		}

		let welcome;
		if (window.VXConfig.signupInfo && !Flux.VXMobile.isEntryPage()) {
			welcome = (
				<ModalWelcome isMobile={true} />
			);
		}

		// conversion chat candidate check
		let modalboxConversionchat = null;
		if (Flux.Guest.isCandidateForConversionChat()) {
			const date = new Date(window.VXConfig.conversionChatCandidateCountdownEnd * 1000);

			modalboxConversionchat = <ModalBoxTrialConversionChat end={date} isMobile={true} />;
		}

		if (Flux.Browser.isTestDevice()) {
			alert(JSON.stringify(this.state));
		}

		const loaderProps = {...this.state.loaderProps};
		if (this.props.location.pathname === MobileRoutes.getLink(MobileRoutes.VXMOBILE)) {
			loaderProps.overlayClassName = '-black';
		}

		let privateTicketShowInvitation = null;
		if (this.state.privateTicketShowInvitation) {
			privateTicketShowInvitation = (
				<PrivateTicketShowInvitationBox
					{...this.state.privateTicketShowInvitation}
					onClose={() => {
						this.setState({privateTicketShowInvitation: null});
					}}
				/>
			);
		}

		if (this.state.isOnline && Flux.Browser.supportsFlexbox()) {
			return (
				<div className={rootClasses}>
					<SecondSearchContext.Provider value={{state: this.state, updateTopSearchShowed: this.updateTopSearchShowed}}>
						<div className={'vxmobile-content vxmobile-content--navbar'} ref={ref => this.setMobileContentWrapperRef(ref)}>
							{conversionBanner}
							{this.state.showFifteenMinutesPreviewCountdown && <FifteenMinutesPreviewCountdown onEnd={this.hideFifteenMinutesPreviewCountdown} />}
							{navigation}
							{privateTicketShowInvitation}
							{window.VXConfig.guestId !== 0 && window.VXConfig.isWebPushEnabled ? <WebPushSubscribe isMobile={true} /> : null}
							<div id={"mobile-content-wrapper"}
							     className={"wrapper" + (guestIsEligibleForConversionChat ? ' conversion-chat-mobile' : '')}
							>
								<FloatingNotifications />
								<div className="content">
                                    <StatusBarWrapper config={VXConfig.statusBarConfig} />
									{<BonusCodeInfoBannerWithNotPayingGuard isMobile={true} />}
									<Suspense fallback={<LogoLoader pulse={true} />}>
										<DefaultRouter {...this.props} />
									</Suspense>
								</div>
							</div>
						</div>
					</SecondSearchContext.Provider>

					{!Flux.VXMobile.isEntryPage()
                     && ![
                        MobileRoutes.getLink(MobileRoutes.CAMS),
                        MobileRoutes.getLink(MobileRoutes.CAMS_SLUG),
                        MobileRoutes.getLink(MobileRoutes.MESSENGER),
                        MobileRoutes.getLink(MobileRoutes.SPECIAL_ADVENT_CALENDAR),
                        MobileRoutes.getLink(MobileRoutes.SPECIAL_TWENTY_FIVE_YEARS)
                     ].includes(this.props.location.pathname) &&
						<Conversion isMobile={true} />}
					<Survey isMobile={true} />
					{(this.state.showLoader || this.state.showReloadFallback)
						&& <LogoLoader showOverlay={true} pulse={true} showReload={this.state.showReloadFallback} {...loaderProps}>
							{this.state.showReloadFallback && <div className="logo-loader__fallback">{Translations.get('LoaderFallbackText')}</div>}
						</LogoLoader>}
					{this.state.showDataProtectionBar &&
						<DataProtectionBar isMobile={true}
						                   agbUrl={MobileRoutes.getLink(MobileRoutes.TERMS_OF_SERVICE)}
						                   dataProtectionUrl={MobileRoutes.getLink(MobileRoutes.DATA_PROTECTION)}
						/>}
					{welcomeBanner}
					{welcome}
					{modalboxConversionchat}
					{privateTicketShowInvitation}
					{(Flux.Guest.isLoggedIn() || Flux.Guest.isAnonymousVoicecall()) && <VXMessengerChat />}
				</div>
			);
		} else if (!this.state.isOnline) {
			return (
				<div className={rootClasses}>
					<div className={'vxmobile-content vxmobile-content--navbar'} ref={ref => this.setMobileContentWrapperRef(ref)}>
						{navigation}
						<div className="wrapper">
							<div className="vxmobile__offline--container -default-padding">
								<div className="vxmobile__offline--image">
									{!this.state.hideOfflineIcon && <img src="/assets/img/icon_offline.svg" onError={this.onOfflineImageError} />}
								</div>
								<div className="vxmobile__offline--text">
									{Translations.get('BrowserOffline')}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (<div className={'not-supported__wrapper' + (this.state.isLandscape ? '--landscape' : '')}>
			<div className="not-supported__label__container">
				<span
					className={'not-supported__label--highlighted ' + (this.state.isLandscape ? '--landscape' : '')}
				>{Translations.get('NotSupportedSorry')}</span>
				<span className="not-supported__label">{Translations.get('NotSupported')}</span>
			</div>
		</div>);

	}
}


VXMobile.propTypes = {
	children: PropTypes.node,
	location: PropTypes.object,
    navigate: PropTypes.func,
};

export default withRouter(VXMobile);
