import React, {useState}         from 'react';
import PropTypes                 from 'prop-types';
import {Link}                    from 'react-router-dom';
import MobileRoutes              from '../Router/MobileRoutes';
import SidebarWrapper, {FROM}    from './SidebarWrapper';
import Translations              from '../../../utils/Translations';
import {VXPay}                   from '../../../utils/VXPay';
import {getMyVisitXMenuItems}    from '../Navigation/Helper';
import VXButtonOutline           from "../../SimpleElements/VXButton/Presets/VXButtonOutline";
import STYLES                    from "../../SimpleElements/VXButton/EnumVXButton";
import {
	VXButtonLineConfig,
	VXButtonLineStyle,
}                                from "../../SimpleElements/VXButton/VXButtonConfig";
import {trackNavbarClick}        from '../../../utils/GAHelper';
import NoPermission              from '../NoPermission/NoPermission';
import Flux                      from '../../../flux/Flux';
import VipLogout                 from '../../ExitIntent/VipLogout';
import {generateKey,
		shouldShowVipExitIntent} from '../../../utils/CommonUtils';

let _touchStart = 0;

function getItems(items) {
	const tmp = [];
	items.map((item, index) => {
		tmp.push(
			<li key={generateKey("vxmenu-item" + index, tmp.length)}>
				<Link to={item.link}
				      className={"vxmenu__menu-item" + (index === 0 ? " vxmenu__menu-item--border-top" : "")}
				      onClick={(event) => {
					      typeof item.callback === 'function' ? item.callback(event) : Flux.VXMobile.closeMenu(Flux.Constants.VXMobile.Menus.MY_VISIT_X);
					      item.gaTracking && trackNavbarClick(item.gaTracking, item.link);
				      }}
				>
					<div className="h-flex -align-baseline">
						{item.name}
						{typeof item.value === 'number' && <span className="vxmenu__menu-text vxmenu__menu-text--gray h-ml-5"> ({item.value})</span>}
						{item.special && <span className="vxmenu__menu-text vxmenu__menu-text--green h-ml-5"> {item.special}</span>}
						{item.isNew && <span className="h-pl-10 navbar-new-icon">{Translations.get('BadgeNew')}</span>}
					</div>
					{item.buttonText && <VXButtonOutline line1={[new VXButtonLineConfig(item.buttonText, new VXButtonLineStyle([STYLES.LIGHT]))]}
					                                     icon={"-icon-single-arrow-right-line"}
					                                     iconPosition="right"
					                                     onClick={(event) => item.buttonCallback(event)}
					/>}
					{item.icon && <i className={"icon " + item.icon}></i>}
				</Link>
			</li>
		);
	});
	return tmp;
}

function getVipClubBadge() {
	return <div className="vxmenu__vip-badge">VIP Club</div>;
}

function onTouchStart(e) {
	_touchStart = e.changedTouches[0].screenY;
}

function onTouchMove(e) {
	if (_touchStart + 90 < e.changedTouches[0].screenY) {
		Flux.VXMobile.closeMenu(Flux.Constants.VXMobile.Menus.MY_VISIT_X);
		_touchStart = 0;
	}
}

function MyVisitX({guest}) {
	const menuItems = getMyVisitXMenuItems(guest);
	const [showVipExitIntent, setShowVipExitIntent] = useState(false);
	const [goToVIPClub, setGoToVIPClub] = useState(false);

	const profileRoute = MobileRoutes.getLink(MobileRoutes.MYVISITX_GUEST_PROFILE);
	const vipClubRoute = MobileRoutes.getLink(MobileRoutes.VIP_CLUB);

	const onLogoutClicked = (e) => {
		e.preventDefault();
		if (shouldShowVipExitIntent() && !Flux.Guest.isVip() && !showVipExitIntent) {
			setShowVipExitIntent(true);
		} else {
			VXPay.logout();
		}
	};

	return (
		<nav className="vxmenu vxmenu--hidden" onTouchStart={onTouchStart} onTouchMove={onTouchMove}>
			<div className="vxmenu__top">
				<Link to={MobileRoutes.getLink(MobileRoutes.VXMOBILE)}><i className="vxmenu__menu-icon icon -icon-home-full"></i></Link>
				<Link to="#" onClick={onLogoutClicked}>
					{Translations.get("NavbarGuestMenuLogOut")}
					<i className="vxmenu__menu-icon vxmenu__menu-icon--ml-10 icon -icon-power-line"></i>
				</Link>
			</div>
			<ul className="vxmenu__menu vxmenu__menu--no-margin">
				<li className={"vxmenu__menu-header__wrapper vxmenu__menu-header__wrapper--180" + (guest.vip ? " vxmenu__menu-header__wrapper--vip" : "")}>
					<div className="sidebar__wrapper-shadow sidebar__wrapper-shadow--gray"></div>
					<Link to={goToVIPClub ? vipClubRoute : profileRoute} className="vxmenu__menu-header" onClick={() => Flux.VXMobile.closeMenu(Flux.Constants.VXMobile.Menus.MY_VISIT_X)}>
						<div className="vxmenu__menu-header__avatar">
							{guest.vip && <i className="vxmenu__menu-icon vxmenu__menu-icon--gold icon -icon-person-vip"></i>}
							<img className={"vxmenu__menu-header__avatar-img" + (guest.vip ? " vxmenu__menu-header__avatar-img--vip" : "")}
							     src={guest.avatar}
							/>
						</div>
						<div className="vxmenu__menu-header__name">{guest.name}</div>
						<div
							onMouseEnter={() => setGoToVIPClub(true)}
							onMouseLeave={() => setGoToVIPClub(false)}
							className="vxmenu__menu-header__vip">
							{guest.vip ? (getVipClubBadge()) :
								(<div>
										<i className="vxmenu__menu-icon vxmenu__menu-icon--gold vxmenu__menu-icon--mr-10 icon -icon-person-vip"></i>
										<span>{Translations.get("NavbarGuestMenuBecomeVip")}</span>
										<i className="vxmenu__menu-icon vxmenu__menu-icon--gold vxmenu__menu-icon--ml-10 icon -icon-single-arrow-right-line"></i>
									</div>
								)}
						</div>
					</Link>
				</li>
			</ul>
			{showVipExitIntent && <VipLogout onLogoutClick={onLogoutClicked} incentive={Flux.Conversion.getExitIntentLogoutIncentive()} />}
			<ul className="vxmenu__menu vxmenu--scroll vxmenu__menu--no-margin">
				{getItems(menuItems)}
			</ul>
		</nav>
	);
}

MyVisitX.propTypes = {
	guest: PropTypes.shape({
		name:                      PropTypes.string,
		isVip:                     PropTypes.bool,
		vip:                       PropTypes.bool,
		pinnedFavoriteCount:       PropTypes.number,
		pinnedFavoriteOnlineCount: PropTypes.number,
		pinnedPhotosCount:         PropTypes.number,
		pinnedVideosCount:         PropTypes.number,
		avatar:                    PropTypes.string,
		balanceFormatted:          PropTypes.string,
		unreadMessageCount:        PropTypes.number,
	}),
};


function MyVisitXSidebar({isMenuOpen, guest}) {
	return (
		<SidebarWrapper openMenu={isMenuOpen} from={FROM.BOTTOM}>
			{guest && <MyVisitX guest={guest} />}
			{!guest && <NoPermission />}
		</SidebarWrapper>
	);

}

MyVisitXSidebar.propTypes = {
	isMenuOpen: PropTypes.bool,
	guest:      PropTypes.shape({
		name:                      PropTypes.string,
		isVip:                     PropTypes.bool,
		pinnedFavoriteCount:       PropTypes.number,
		pinnedFavoriteOnlineCount: PropTypes.number,
		pinnedPhotosCount:         PropTypes.number,
		pinnedVideosCount:         PropTypes.number,
		avatar:                    PropTypes.string,
		balanceFormatted:          PropTypes.string,
		unreadMessageCount:        PropTypes.number,
	}),
};

export default MyVisitXSidebar;
export {MyVisitX};
