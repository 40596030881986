/* globals VXConfig */
import React, {Suspense}           from 'react';
import {render}                    from 'react-dom';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Flux                        from '../../flux/Flux';
import {getUrlParams}              from "../../utils/CommonUtils";
import {openModalBox}              from "../../components/ModalBox/ModalBoxFactory";
import WebmasterCookieHelper       from "../../utils/WebmasterCookieHelper";
import {VXPay}                     from "../../utils/VXPay";
import * as ModalPornmeConversion  from "../../view/ModalPornmeConversion";
import * as ModalPornmeMigration   from "../../view/ModalPornmeMigration";
import * as ServiceWorkerRegistrar from "../../view/VXMobile/ServiceWorkerRegistrar";
import LangRedirectPopup           from "../../components/LangRedirectPopup";
import * as CookieConsent          from "../CookieConsent";
import {showLoader}                from '../../components/Loader/LoaderFactory';
import VXMobile                    from '../../components/VXMobile/VXMobile';
import 'vite/modulepreload-polyfill';
import LogoLoader                  from "../../components/Loader/LogoLoader";
import "../../../../src/VX/Asset/Less/VXMobile/View/VXMobile.less";

function run() {
	window.hasRunEntryFunc = true;
	polyFill();

	const {
		      indexContainerId,
		      language,
		      boomerUrl,
		      mobileData,
		      messengerInitData,
		      availableCollections,
		      availableFilters,
		      initialCollection
	      } = window.indexData;

	WebmasterCookieHelper.setWebmasterCookiesOnUserInteraction();

	if (VXConfig.isEntryPage && VXConfig.entryPageId === Flux.Constants.EntryPage.Ids.ADWORDS_STARTPAGE) {
		document.addEventListener('click', function(e) {
			e.stopPropagation();
			e.preventDefault();
			VXPay.openSignupOrLogin();
		}, true);
	}

	Flux.VXMobile.init(mobileData);
	Flux.SearchResult.init(language);
	Flux.ProSearch.initMobile(Flux.Constants.ActorSearch.SearchTypes.SEARCH_TYPE_DEFAULT, availableCollections, availableFilters);
	Flux.ProSearch.doInit(initialCollection || Flux.Constants.ActorSearch.DEFAULT_CATEGORY, Flux.Constants.ActorSearch.SearchTypes.SEARCH_TYPE_DEFAULT);

	const urlParams = getUrlParams(window.location.href);

	if (typeof urlParams.openFeedback !== 'undefined') {
		Flux.Survey.openSurvey(Flux.Constants.SurveyTypes.FEEDBACK);
	}

	if (typeof urlParams.openModal !== 'undefined') {
		openModalBox(urlParams.openModal);
	}

	// renew accessToken if it need to be regenerated
	document.addEventListener('ajaxSuccess', (e, xhr) => {
		const newAccesstoken = xhr.getResponseHeader('accessToken');
		if (newAccesstoken && newAccesstoken !== window.VXConfig.accessToken) {
			window.VXConfig.accessToken = newAccesstoken;
		}
		const newAccesstokenTTL = parseInt(xhr.getResponseHeader('accessTokenTTL'));
		if (newAccesstokenTTL) {
			Flux.Browser.updateExpireStamp(newAccesstokenTTL);
		}
	});

	Flux.Messenger.init(language,
		messengerInitData.channels,
		undefined,
		messengerInitData.showOnline,
		messengerInitData.gifts,
		messengerInitData.boughtGifts,
		messengerInitData.navbarMessages
	);

	Flux.Guest.init();

	const container = document.getElementById(indexContainerId);
	if (container) {
		render(
			<Suspense fallback={<LogoLoader pulse={true} />}>
				<BrowserRouter>
                    <Routes>
                        <Route path="*" element={<VXMobile />} />
                    </Routes>
				</BrowserRouter>
			</Suspense>, container);
	}

	if (mobileData.guest) {
		Flux.Logger.init();
		Flux.Notification.init(boomerUrl);
	}

	const spinner = document.getElementById('vxmobile-spinner');
	if (spinner) {
		spinner.parentNode.removeChild(spinner);
	}

	// handle expiration check
	Flux.Browser.updateExpireStamp(window.VXConfig.accessTokenTTL);

	Flux.Browser.addVisibilityChangeListener((visible) => {
		if (visible && Flux.Browser.checkExpireStamp()) {
			window.location.reload();
		}
	});

	showLoader();

	// handle index data
	if (!window.indexData.isDevelopmentServer) {
		ServiceWorkerRegistrar.run(window.indexData.autoIncrementorCachingTag);
	}
	if (window.indexData.showLangRedirectPopup) {
		const container = document.getElementById(window.indexData.languageRedirectContainerId);

		if (container) {
			render(<LangRedirectPopup cookieName={window.indexData.languageRedirectCookie}
			                          redirectUrl={window.indexData.languageRedirectUrl}
			/>, container);
		}
	}
	if (window.indexData.showPornmeConversionPopup) {
		ModalPornmeConversion.run(window.indexData.pornmeContainerId, window.indexData.pornmeClassName);
	}
	if (window.indexData.showPornmeMigrationPopup) {
		ModalPornmeMigration.run(window.indexData.pornmeMigrationContainerId, window.indexData.pornmeMigrationClassName, window.indexData.pornmeMigrationConfig);
	}

	if (window.indexData.cookieConsent) {
		const {categories, urls, language, isMobile} = window.indexData.cookieConsent;
		CookieConsent.run(categories, urls, language, isMobile);
	}
}

function polyFill() {
	"hasOwn" in Object || (Object.hasOwn = Object.call.bind(Object.hasOwnProperty));
}

if (!window.hasRunEntryFunc) {
	if (document.readyState !== 'loading') {
		run();
	} else {
		window.addEventListener('DOMContentLoaded', run);
	}
}
