import React                  from 'react';
import PropTypes              from 'prop-types';
import SidebarWrapper, {FROM} from './SidebarWrapper';
import Translations           from '../../../utils/Translations';
import Flux                   from '../../../flux/Flux';
import { generateKey }        from '../../../utils/CommonUtils';

class CategoriesFilter extends React.Component {
	constructor(props) {
		super(props);
        
		this.state = {
			filterList: Flux.TileGrid.getFilterList(),
		};
        
		this.checked = 0;

		this.filterListChange    = this.filterListChange.bind(this);
		this.onShowAllGirlsClick = this.onShowAllGirlsClick.bind(this);
	}
    
	componentDidMount() {
		Flux.TileGrid.addFilterListChangeListener(this.filterListChange);
	}

	componentWillUnmount() {
		Flux.TileGrid.removeFilterListChangeListener(this.filterListChange);
	}

	filterListChange(filterList) {
		this.setState({filterList});
	}
    
	onMenuCloseClick() {
		Flux.VXMobile.closeMenu(Flux.Constants.VXMobile.Menus.CATEGORY_FILTER);
	}

	onShowAllGirlsClick() {
		const filterList = Flux.TileGrid.getFilterList();
		filterList.items.map((item) => {
			item.callback(false, item.name, item.field);
		});
		this.setState({filterList});
	}

	onFilterClick(callback, e){
		e.preventDefault();
		callback();
	}

	getGroupItemList(listItem) {
		const listItems = [listItem];
		const items = [];
		listItems.map((listItem, idx) => {
			const isChecked = listItem.isActive;
			const id = listItem.name + idx;
			if (isChecked) {
				this.checkedCount++;
			}

			items.push(
				<li  
					key={generateKey('vxfilter-menu-list-item' + idx, items.length)}
					className="vxfilter-menu__list-item" 
					onClick={(e) => this.onFilterClick(() => listItem.callback(!listItem.isActive, listItem.name, listItem.field), e)}
				>
					<input 
						id={id} 
						onChange={(e) => this.onFilterClick(() => listItem.callback(!listItem.isActive, listItem.name, listItem.field), e)}
						className="vxfilter-menu__checkbox" 
						type="checkbox" 
						checked={isChecked}
					/>
					<label id={'label-' + id} htmlFor={id} className={`vxfilter-menu__label ${isChecked ? 'vxfilter-menu__label--active' : ''}`} >
						<span  className="vxfilter-menu__checkbox-double"></span>
						{listItem.sidebarText}
					</label>
				</li>
			);
		});

		return items;
	}

	getGroupItem(groupItem) {
		return (
			<div className="vxfilter-menu__group-item">
				<ul className="vxfilter-menu__list">
					{this.getGroupItemList(groupItem)}
				</ul>
			</div>
		);
	}

	getGroups() {
		this.checkedCount = 0;
		const groupedItems = [];
		this.state.filterList.items.map((groupOrder, index) => {
			groupedItems.push(
				<div key={generateKey('vxfilter-menu-group' + index, groupedItems.length)} className="vxfilter-menu__group">
					{this.getGroupItem(groupOrder)} 
				</div>
			);
		});

		return groupedItems;
	}

	render() {
		const hasFilterList = this.state.filterList && this.state.filterList.items && this.state.filterList.items.length > 0;
		const quantity      = hasFilterList && this.state.filterList.items.filter(item => item.isActive).length;
		return (
			<div className="vxfilter-menu__container" >
				<div className="vxfilter-menu">
					<div className="vxfilter-menu__headline">
						<i className="vxfilter-menu__headline-close icon -icon-close-line" onClick={this.onMenuCloseClick}></i>
						{Translations.get('Filter')}&nbsp;<span className="vxfilter-menu__headline-qty">{quantity}</span>
					</div>

					<div className="vxfilter-menu__group-list">
						{hasFilterList && this.getGroups()}
					</div>

					<div className="vxfilter-menu__reset" onClick={this.onShowAllGirlsClick}>
						<i className="vxfilter-menu__reset-icon icon -icon-reload-reverse"></i>
						{Translations.get('VideoGridFilterEmpty')}
					</div>

				</div>
				<div className="vxfilter-menu__button" onClick={this.onMenuCloseClick}>
					<span className="vxfilter-menu__button-qty">{this.state.filterList.quantity} Cams</span>
					<span className="vxfilter-menu__button-save">{Translations.get('VideoGridFilterApply')}</span>
				</div>
			</div>
		);
	}
}


function CategoriesFilterSidebar({isMenuOpen}) {
	return (
		<SidebarWrapper openMenu={isMenuOpen} showShadow={false} from={FROM.RIGHT} showNavbar={false}>
			<CategoriesFilter />
		</SidebarWrapper>
	);
}

CategoriesFilterSidebar.propTypes = {
	isMenuOpen:    PropTypes.bool,
};

export default CategoriesFilterSidebar;
export {CategoriesFilter};