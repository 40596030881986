import React                   from 'react';
import PropTypes               from 'prop-types';
import Header                  from './Header';
import Login                   from './Login';
import Navbar                  from '../Navbar/Navbar';
import Flux                    from '../../../flux/Flux';
import MenuSidebar             from '../Sidebar/MenuSidebar';
import MyVisitXSidebar         from '../Sidebar/MyVisitXSidebar';
import SearchSidebar           from '../Sidebar/SearchSidebar';
import MobileRoutes            from '../Router/MobileRoutes';
import CategoriesFilterSidebar from '../Sidebar/CategoriesFilterSidebar';
import SecondNavbar            from "../Navbar/SecondNavbar";
import {getSecondNavigation}   from "./Helper";

const NotificationsSidebar = React.lazy(() => import('../Sidebar/NotificationsSidebar'));
const Introduction         = React.lazy(() => import("./Introduction/Introduction"));
const CamsFilterSidebar    = React.lazy(() => import('../Sidebar/CamsFilterSidebar'));
const NavbarContainerLight = React.lazy(() => import('../Navbar/NavbarContainerLight'));


class Index extends React.PureComponent {
	constructor(props) {
		super(props);
		const isLoggedIn = Flux.Guest.isLoggedIn();
		this.state       = {
			isLoggedIn,
			menuOpen:              false,
			guest:                 null,
			hideSecondNavHeadline: false,
		};

		this.onMenuChange   = this.onMenuChange.bind(this);
		this.loadGuestData  = this.loadGuestData.bind(this);
		this.getMenusStatus = this.getMenusStatus.bind(this);
		this.handleScroll   = this.handleScroll.bind(this);
		this.onGuestChange  = this.onGuestChange.bind(this);
	}

	componentDidMount() {
		Flux.VXMobile.addMenuChangeListener(this.onMenuChange);
		Flux.VXMobile.addContentScrollChangeListener(this.handleScroll);
		Flux.Guest.addGuestChangeListener(this.onGuestChange);

		this.loadGuestData();

		this.props.parentCallback(this.getMenusStatus());
	}

	componentWillUnmount() {
		Flux.VXMobile.removeMenuChangeListener(this.onMenuChange);
		Flux.VXMobile.removeContentScrollChangeListener(this.handleScroll);
		Flux.Guest.removeGuestChangeListener(this.onGuestChange);
	}

	handleScroll(scrollPosition) {
		const hideSecondNavHeadline = scrollPosition && scrollPosition > (this.props.showSpace ? 100 : 50);
		this.setState({hideSecondNavHeadline});
	}

	onGuestChange() {
		const guestData = this.state.guest;
		if (guestData) {
			guestData.balanceFormatted = Flux.Guest.getBalanceFormatted();

			this.setState({...guestData});
		}
	}

	loadGuestData() {
		if (this.state.isLoggedIn) {
			Flux.Guest.getMyVisitXSidebarData((data) => {
				if (data.data) {
					const guest = {
						...data.data.guest,
						avatar: data.data.guest.avatar.url,
					};
					this.setState({guest});
				}
			});
		}
	}

	onMenuChange() {
		const menuOpen = Flux.VXMobile.getMenuOpen();
		if (menuOpen !== this.state.menuOpen) {
			this.setState({
				menuOpen: menuOpen,
			});
		}
	}

	getMenusStatus() {
		const menus = {
			myVisitX:       this.state.menuOpen === Flux.Constants.VXMobile.Menus.MY_VISIT_X,
			mainMenu:       this.state.menuOpen === Flux.Constants.VXMobile.Menus.NAVI,
			search:         this.state.menuOpen === Flux.Constants.VXMobile.Menus.SEARCH,
			camFilter:      this.state.menuOpen === Flux.Constants.VXMobile.Menus.CAM_FILTER,
			categoryFilter: this.state.menuOpen === Flux.Constants.VXMobile.Menus.CATEGORY_FILTER,
			notifications:  this.state.menuOpen === Flux.Constants.VXMobile.Menus.NOTIFICATIONS,
		};

		return menus;
	}

	getSecondNavigation() {
		const secondNavigation = getSecondNavigation(this.props.currentPath, this.state.isLoggedIn);
		if (secondNavigation && secondNavigation.items && secondNavigation.items.length > 0) {
			return secondNavigation;
		}
		return null;
	}

	render() {
		const menus              = this.getMenusStatus();
		const isLandingpage      = this.props.currentPath === MobileRoutes.getLink(MobileRoutes.VXMOBILE);
		const secondNavigation   = this.getSecondNavigation();
		const countNotifications = this.props.notifications.filter(notification => notification.unread).length;
		if (this.props.mobileContentWrapperRef && secondNavigation && secondNavigation.headline) {
			this.props.isNavbarHidden ? this.props.mobileContentWrapperRef.classList.remove("vxmobile-content--headline") : this.props.mobileContentWrapperRef.classList.add("vxmobile-content--headline");
		}
		let navigations;

		const elem = document.getElementById('mobile-content-wrapper');
		if (elem) {
			if (secondNavigation) {
				elem.classList.add('second-nav');
			} else {
				elem.classList.remove('second-nav');
			}
		}

		if (!this.props.isNavbarHidden && !this.props.showNavbarLight) {
			navigations = (
				<div id="mobile-navigation-wrapper"
				     className={'navigation-wrapper' + (Flux.Guest.isEligibleForConversionChat() ? ' conversion-chat-mobile' : '')}
				>
					<Header countNotifications={countNotifications} isLoggedIn={this.state.isLoggedIn} menus={menus} />
					{secondNavigation && <SecondNavbar {...secondNavigation} hideSecondNavHeadline={this.state.hideSecondNavHeadline} />}
					{isLandingpage && this.props.showLoginButtons && <div>
						<div className="vxheader__placeholder"></div>
						<Login position="fixed" /></div>}
				</div>
			);
		} else if (!this.state.isNavbarHidden && this.props.showNavbarLight) {
			navigations = (
				<div className={'navigation-wrapper'}>
					<NavbarContainerLight />
					<div className="spacer" />
				</div>
			);
		}
		return (
			<div id="vxnavigation" className="vxnavigation">
				{this.state.guest && <Introduction guestName={this.state.guest.name} menus={menus} />}
				{navigations}
				<MenuSidebar isMenuOpen={menus.mainMenu} isLoggedIn={this.state.isLoggedIn} />
				{<MyVisitXSidebar isMenuOpen={menus.myVisitX} guest={this.state.guest} />}
				{this.state.isLoggedIn && this.state.guest && <NotificationsSidebar isMenuOpen={menus.notifications}
				                                                                    notifications={this.props.notifications}
				                                                                    countNotifications={countNotifications}
				/>}
				<SearchSidebar isMenuOpen={menus.search} />
				<CategoriesFilterSidebar isMenuOpen={menus.categoryFilter} />
				{this.props.currentPath.startsWith(MobileRoutes.getLink(MobileRoutes.CAMS)) &&
					<CamsFilterSidebar isMenuOpen={menus.camFilter} currentPath={this.props.currentPath} />}
				<Navbar isLoggedIn={this.state.isLoggedIn}
				        menus={menus}
				        countUnreadMessages={this.props.countUnreadMessages}
				        currentPath={this.props.currentPath}
				/>
			</div>
		);
	}
}

Index.propTypes = {
	showLoginButtons:        PropTypes.bool,
	countUnreadMessages:     PropTypes.number,
	currentPath:             PropTypes.string,
	countFavorites:          PropTypes.number,
	notifications:           PropTypes.array,
	showNavbarLight:         PropTypes.bool,
	isNavbarHidden:          PropTypes.bool,
	showLoader:              PropTypes.bool,
	showSpace:               PropTypes.bool,
	mobileContentWrapperRef: PropTypes.object,
	parentCallback:          PropTypes.func,
};

Index.defaultProps = {
	showLoginButtons:        false,
	countUnreadMessages:     0,
	currentPath:             '/',
	countFavorites:          0,
	notifications:           [],
	showNavbarLight:         false,
	isNavbarHidden:          false,
	showLoader:              true,
	showSpace:               false,
	mobileContentWrapperRef: null,
};

export default Index;
