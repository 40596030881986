import React           from 'react';
import PropTypes       from 'prop-types';
import FilterButton    from '../ProSearch/FilterButton';
import Translations    from '../../../utils/Translations';
import Flux            from '../../../flux/Flux';
import { generateKey } from '../../../utils/CommonUtils';

function getFilterList(filterList = [], onButtonClick) {
	const maxFilter      = Flux.Browser.isLandscape() ? 4 : 2;
	const filteredList   = filterList.filter(item => item.isActive);
	const useButtonClick = (onButtonClick && typeof onButtonClick === 'function');
	return filteredList.map((item, idx) => {
		const key = generateKey('vxfilter-list-item', idx);
		if (idx > maxFilter) {
			return (null);
		} if (idx === maxFilter) {
			return (<li key={key} className="vxfilter__list-item" onClick={useButtonClick ? onButtonClick : toggleMenu}>...</li>);
		} if (item.filterText.split(' ').length > 1 && filteredList.length > 2) {
			return (<li key={key} className="vxfilter__list-item" onClick={(e) => onFilterClick(() => item.callback(!item.isActive, item.name, item.field), e)}>{item.filterText.split(' ')[0]} ...<i className="icon -icon-close-full" /></li>);
		} 
		return (<li key={key} className="vxfilter__list-item" onClick={(e) => onFilterClick(() => item.callback(!item.isActive, item.name, item.field), e)}>{item.filterText}<i className="icon -icon-close-full" /></li>);
	}
	);
}

function toggleMenu() {
	Flux.VXMobile.toggleMenu(Flux.Constants.VXMobile.Menus.CATEGORY_FILTER);
}

function setFilterList(filterList) {
	if (filterList.items) {
		toggleMenu();
	}
}

function onFilterClick(callback, e){
	e.preventDefault();
	callback();
}

function CategoriesFilter({filterList, onButtonClick}) {
	if (!filterList || !filterList.items || filterList.items.length < 1) {
		return null;
	}
	const filteredList   = getFilterList(filterList.items, onButtonClick);
	const hasFilter      = filteredList.length > 0;
	const useButtonClick = (onButtonClick && typeof onButtonClick === 'function');
	!useButtonClick && Flux.TileGrid.setFilterList(filterList);
	return (
		<div className="vxfilter">
			<div className="vxfilter__list-container">
				<span className={"vxfilter__label" + (hasFilter ? "" : " vxfilter__label--no-filter")}>{hasFilter ? Translations.get('Filter') : Translations.get('NoFilterSet')}</span>
				<div className="vxfilter__list">{filteredList}</div>
			</div>
            
			<FilterButton onClick={useButtonClick ? onButtonClick : () => setFilterList(filterList)} show={true}/>          
		</div>
	);
}

CategoriesFilter.propTypes = {
	filterList:    PropTypes.object,
	onButtonClick: PropTypes.func,
};

CategoriesFilter.defaultProps = {
	filterList:    {},
	onButtonClick: null,
};

export default CategoriesFilter;

