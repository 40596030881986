import React     from 'react';
import Flux      from '../flux/Flux';
import PropTypes from 'prop-types';

const withOrientation = Component => {
	const C = props => (
		<OrientationWrapper>
			{({...orientationProps}) => (
				<Component
					{...props}
					{...orientationProps}
				/>
			)}
		</OrientationWrapper>
	);

	return C;
};

export default class OrientationWrapper extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLandscape: Flux.Browser.isLandscape(),
		};

		this.onWindowResize = this.onWindowResize.bind(this);
	}

	componentDidMount() {
		Flux.Browser.addWindowResizeListener(this.onWindowResize);
	}

	componentWillUnmount() {
		Flux.Browser.removeWindowResizeListener(this.onWindowResize);
	}

	onWindowResize() {
		this.setState({
			isLandscape: Flux.Browser.isLandscape(),
		});
	}

	render() {
		return this.props.children({
			isLandscape: this.state.isLandscape,
		});
	}
}

OrientationWrapper.propTypes = {
	children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export {
	OrientationWrapper,
	withOrientation,
};
