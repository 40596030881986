import React                                 from 'react';
import {Link}                                from 'react-router-dom';
import PropTypes                             from 'prop-types';
import Flux                                  from '../../../flux/Flux';
import MobileRoutes                          from '../Router/MobileRoutes';
import CircleLoader                          from '../../Loader/CircleLoader';
import Pagination                            from '../../Pagination';
import TileGrid                              from '../../TileGrid/TileGrid';
import Sorter                                from '../../SimpleElements/Sorter';
import GuestAccess                           from '../../../utils/GuestAccess';
import {scrollContainerToElem, scrollToElem} from '../../../utils/CommonUtils';
import GlobalEventHandler                    from '../../../utils/GlobalEventHandler';
import assign                                from 'object-assign';
import Translations                          from '../../../utils/Translations';
import TranslationHelper                     from '../../../utils/TranslationHelper';
import {VXPay, VXPayAction}                  from "../../../utils/VXPay";
import HistoryStateHelper                    from '../../../utils/HistoryStateHelper';
import Headline                              from '../Layout/Headline';
import CategoriesFilter                      from '../Categories/CategoriesFilter';
import TextSearch                            from '../../Text/TextSearch';
import withNavigate from '../../HigherOrderComponents/Utility/withNavigate';

let boxId = 1;

class TileGridBox extends React.Component {
	static parseGridConfig(gridConfig, defaultState) {
		defaultState = defaultState || null;

		const state = {};

		if (gridConfig) {
			state.gridConfig = gridConfig;
            state.searchPattern = gridConfig.searchPattern;

			if (gridConfig.pagination) {
				state.currentPage = gridConfig.pagination.currentPage;
				state.maxPage     = gridConfig.pagination.maxPage;
			}

			if (gridConfig.searchTags && gridConfig.searchTags.length > 0) {
				state.tag = gridConfig.searchTags[0];
			}

			if (gridConfig.sortOptions) {
				state.sortOptions = gridConfig.sortOptions;

				for (let s = 0; s < state.sortOptions.length; s++) {
					if (state.sortOptions[s].selected) {
						state.order   = state.sortOptions[s].order;
						state.sortKey = state.sortOptions[s].sort;

						break;
					}
				}
			}
		}

		let ds;
		if (defaultState) {
			for (ds in defaultState) {
				if (Object.hasOwn(defaultState, ds) && typeof state[ds] === 'undefined') {
					state[ds] = defaultState[ds];
				}
			}
		}
		return state;
	}

	constructor(props) {
		super(props);

		const restoreScrollPosition = Flux.TileGrid.getRestoreScrollPosition() || HistoryStateHelper.hasKey(Flux.Constants.HistoryStateKeys.RESTORE_SCROLL_POSITION);
		const useCache              = this.props.useCache || restoreScrollPosition;

		let tileGridKey = Flux.TileGrid.getTileGridKeyById(this.props.type);

		// make sure to reload previous selection on backwards navigation
		if (useCache && this.props.tileGridId && (this.props.useLoadMorePagination || this.props.useInfiniteScrolling || restoreScrollPosition)) {
			tileGridKey = Flux.TileGrid.getTileGridKeyById(this.props.tileGridId);
		}

		this.loading               = false;
		this.requestPayload        = null;
		this.payload               = {};
		this.restoreScrollPosition = restoreScrollPosition;
		this.scrollPosition        = 0;
		this.useCache              = useCache;

		// restore guest's sort / grid-mode selection
		let settings = {};
		if (this.props.storeSettings) {
			settings = Flux.VXMobile.Guest.getTileGridSettingsByType(this.props.type);
		}

		const page  = this.props.usePagination || this.props.useInfiniteScrolling || this.props.useLoadMorePagination ? 1 : null;
		const sort  = settings && typeof settings.sort !== 'undefined' ? settings.sort : (this.props.useSorting ? this.props.defaultSort : null);
		const order = settings && typeof settings.order !== 'undefined' ? settings.order : (this.props.useSorting ? 'desc' : null);

		let gridConfig;
		if (restoreScrollPosition && tileGridKey && Flux.TileGrid.hasGridData(this.props.type, tileGridKey)) {
			gridConfig = assign({}, Flux.TileGrid.getGridData(this.props.type, tileGridKey));
		} else {
			gridConfig = this.loadGridConfig({useCache: this.useCache, page, sort, order, tag: this.props.initialTag, filterOnly: false, premiumOnly: props.premiumOnly});
		}

		const defaultState = {
			currentPage:  page,
			filterOnline: false,
			gridConfig:   null,
			maxPage:      0,
			order:        null,
			sortKey:      null,
			sortOptions:  null,
			tag:          this.props.initialTag,
			isLandscape:  Flux.Browser.isLandscape(),
            searchPattern:   '',
		};

		const state = TileGridBox.parseGridConfig(gridConfig, defaultState);

		if (gridConfig && typeof gridConfig.totalCount !== 'undefined' && this.props.totalCountHook !== null) {
			this.props.totalCountHook(typeof gridConfig.onlineCount === 'number' ? gridConfig.onlineCount : gridConfig.totalCount);
		}

		if (gridConfig && typeof gridConfig.headline !== 'undefined' && this.props.headlineHook !== null) {
			this.props.headlineHook(gridConfig.headline);
		}

		if (tileGridKey && tileGridKey.sort) {
			state.sortKey = tileGridKey.sort;
		}

		if (tileGridKey && tileGridKey.filterOnline) {
			state.filterOnline = tileGridKey.filterOnline;
		}

		// set element id
		if (this.props.tileGridId) {
			state.id = this.props.tileGridId;
		} else if (this.useCache && this.props.useInfiniteScrolling) {
			state.id = this.props.type;
		} else {
			state.id = 'tile-grid-' + boxId++;
		}

		// set grid mode
		let gridMode = null;
		if (this.props.tileGridId && Flux.TileGrid.getTileGridModeById(this.props.tileGridId)) {
			gridMode = Flux.TileGrid.getTileGridModeById(this.props.tileGridId);
		} else if (typeof settings.gridMode !== 'undefined') {
			gridMode = settings.gridMode;
		}

		if (gridMode !== Flux.Constants.TileGridModes.GRID && gridMode !== Flux.Constants.TileGridModes.LIST) {
			gridMode = Flux.Constants.TileGridModes.GRID;
		}

		state.gridMode = gridMode;

		this.state = state;

		this.getOnButtonClickFn      = this.getOnButtonClickFn.bind(this);
		this.getOnClickFn            = this.getOnClickFn.bind(this);
		this.getPayload              = this.getPayload.bind(this);
		this.loadGridConfig          = this.loadGridConfig.bind(this);
		this.loadMore                = this.loadMore.bind(this);
		this.onCheckboxChange        = this.onCheckboxChange.bind(this);
		this.onContainerScroll       = this.onContainerScroll.bind(this);
		this.onGalleryChange         = this.onGalleryChange.bind(this);
		this.onGridChange            = this.onGridChange.bind(this);
		this.onGridModeChange        = this.onGridModeChange.bind(this);
		this.onGridModeGridClick     = this.onGridModeGridClick.bind(this);
		this.onGridModeListClick     = this.onGridModeListClick.bind(this);
		this.onResetClickFn          = this.onResetClickFn.bind(this);
		this.onWindowResize          = this.onWindowResize.bind(this);
		this.paginateGotoFn          = this.paginateGotoFn.bind(this);
		this.scrollContainerToAnchor = this.scrollContainerToAnchor.bind(this);
		this.sortGotoFn              = this.sortGotoFn.bind(this);
		this.storeSettings           = this.storeSettings.bind(this);
		this.onPinnedMediaChange     = this.onPinnedMediaChange.bind(this);
		this.onSearchPatternChange   = this.onSearchPatternChange.bind(this);
        this.onDescLinkClick         = this.onDescLinkClick.bind(this);
	}

	componentDidMount() {
		Flux.Browser.addWindowResizeListener(this.onWindowResize);
		Flux.Gallery.addGalleryChangeListener(this.onGalleryChange);
		Flux.TileGrid.addGridDataChangeListener(this.onGridChange);
		Flux.TileGrid.addGlobalPinChangeListener(this.onPinnedMediaChange);


		if (this.props.useInfiniteScrolling) {
			Flux.VXMobile.addContainerScrollListener(this.props.scrollContainerId, this.state.id, this.onContainerScroll);
		}

		if (this.useCache && this.props.tileGridId && (this.props.useLoadMorePagination || this.props.useInfiniteScrolling || this.restoreScrollPosition)) {
			const scrollPosition = Flux.TileGrid.getScrollPositionById(this.props.tileGridId);

			if (scrollPosition) {
				this.scrollPosition = scrollPosition;

				Flux.VXMobile.ignoreNextScroll(this.props.scrollContainerId);
				Flux.TileGrid.setRestoreScrollPosition(false);
				Flux.TileGrid.resetScrollPosition(this.props.tileGridId);
				setTimeout(() => {
					document.getElementById(this.props.scrollContainerId).scrollTop = scrollPosition;
				}, 0);
			}
		}

		if (this.props.tileGridId) {
			HistoryStateHelper.setValue(Flux.Constants.HistoryStateKeys.RESTORE_SCROLL_POSITION, true);
		}
	}

	componentWillUnmount() {
		Flux.Browser.removeWindowResizeListener(this.onWindowResize);
		Flux.Gallery.removeGalleryChangeListener(this.onGalleryChange);
		Flux.TileGrid.removeGridDataChangeListener(this.onGridChange);
		Flux.TileGrid.removeGlobalPinChangeListener(this.onPinnedMediaChange);

		if (this.props.useInfiniteScrolling) {
			Flux.VXMobile.removeContainerScrollListener(this.props.scrollContainerId, this.state.id);
		}

		if (this.props.tileGridId) {
			Flux.TileGrid.setScrollPosition(this.props.tileGridId, this.scrollPosition);
			Flux.TileGrid.setTileGridKey(this.props.tileGridId, this.getPayload());
			Flux.TileGrid.setTileGridMode(this.props.tileGridId, this.state.gridMode);
		}
	}

    onDescLinkClick(e, targetUrl) {
		if (e.cancelable) {
			e.preventDefault();
			e.stopPropagation();
		}

		this.props.navigate(targetUrl);
	}

	getOnButtonClickFn() {
		let onClickFn;
		const TileGridType = Flux.Constants.TileGrid;

		switch (this.props.type) {
			case TileGridType.LATEST_ALBUMS:
			case TileGridType.RECOMMENDATIONS_ALBUMS_SIMILAR:
				onClickFn = (e, albumData) => {
					if ((typeof e === 'object') && e.cancelable) {
						e.stopPropagation();
						e.preventDefault();
					}

					let targetUrl = albumData.targetUrl;
					if (!targetUrl) {
						targetUrl = MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_ABOUT, {
							':actorName': albumData.actorName
						});
					}

					if (Flux.Guest.isLoggedIn() && !albumData.isAccessAllowed && albumData.price) {
						GuestAccess.onEnoughMoneyForShop(
							albumData.actorId,
							albumData.price,
							albumData.needAvs,
							albumData.needVip,
							function() {
								const clb = function(actorId, changedAlbumId) {
									if (changedAlbumId === albumData.id) {
										Flux.Gallery.removeGalleryChangeListener(clb);
										GlobalEventHandler.emit(GlobalEventHandler.LOADER_TOGGLE, false);

										this.props.navigate(targetUrl);
									}
								};

								Flux.Gallery.addGalleryChangeListener(clb);
								Flux.Gallery.buyAlbum(albumData.id);
								GlobalEventHandler.emit(GlobalEventHandler.LOADER_TOGGLE, true);
							},
							targetUrl
						);
					} else {
						this.props.navigate(targetUrl);
					}
				};
				break;

			case TileGridType.RECOMMENDATIONS_VIDEOS:
			case TileGridType.RECOMMENDATIONS_VIDEOS_SIMILAR:
				onClickFn = (e, albumData) => {
					if ((typeof e === 'object') && e.cancelable) {
						e.stopPropagation();
						e.preventDefault();
					}

					let targetUrl = albumData.targetUrl;
					if (!targetUrl) {
						targetUrl = MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_VIDEOALBUM_DETAIL, {
							':actorName': albumData.actorName,
							':albumId':   albumData.id,
						});
					}

					if (!albumData.isAccessAllowed && albumData.price) {
						GuestAccess.onEnoughMoneyForShop(
							albumData.actorId,
							albumData.price,
							albumData.needAvs,
							albumData.needVip,
							function() {
								const clb = function(actorId, changedAlbumId) {
									if (changedAlbumId === albumData.id) {
										Flux.Gallery.removeGalleryChangeListener(clb);
										GlobalEventHandler.emit(GlobalEventHandler.LOADER_TOGGLE, false);
										this.props.navigate(targetUrl);
									}
								};

								Flux.Gallery.addGalleryChangeListener(clb);
								Flux.Gallery.buyAlbum(albumData.id);
								GlobalEventHandler.emit(GlobalEventHandler.LOADER_TOGGLE, true);
							},
							targetUrl
						);
					} else if (!Flux.Guest.isLoggedIn()) {
						VXPay.openSignupOrLogin(
							{},
							new VXPayAction(Flux.Constants.ActionTypes.VXPay.REDIRECT_TO, {
								targetUrl: targetUrl,
							})
						);
					} else {
						this.props.navigate(targetUrl);
					}
				};
				break;

			case TileGridType.CATEGORY_CONTENT:
			case TileGridType.FAVORITE_ACTORS:
			case TileGridType.RECOMMENDATIONS_ACTORS:
			case TileGridType.RECOMMENDATIONS_BEST_CHATS:
				onClickFn = (e, actorData) => {
					if ((typeof e === 'object') && e.cancelable) {
						e.stopPropagation();
						e.preventDefault();
					}

					this.props.navigate(
						MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_OVERVIEW, {':actorName': actorData.name})
					);
				};
				break;

			case TileGridType.CATEGORIES:
			case TileGridType.FAVORITE_CATEGORIES:
				onClickFn = (e, targetUrl) => {
					if ((typeof e === 'object') && e.cancelable) {
						e.stopPropagation();
						e.preventDefault();
					}

					this.props.navigate(targetUrl);
				};
				break;

			default:
				break;
		}
		return onClickFn;
	}

	getOnClickFn() {
		let onClickFn;
		const TileGridType = Flux.Constants.TileGrid;

		switch (this.props.type) {
			case TileGridType.BOUGHT_ALBUMS:
			case TileGridType.BOUGHT_ONLY_ALBUMS:
			case TileGridType.GIFTED_ONLY_ALBUMS:
			case TileGridType.LATEST_ALBUMS:
			case TileGridType.PINBOARD_ALBUMS:
			case TileGridType.RECOMMENDATIONS_ALBUMS_SIMILAR:
				onClickFn = (e, albumData) => {
					if ((typeof e === 'object') && e.cancelable) {
						e.stopPropagation();
						e.preventDefault();
					}

					let targetUrl = albumData.targetUrl;
					if (!targetUrl) {
						targetUrl = MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_ABOUT, {
							':actorName': albumData.actorName
						});
					}

					this.props.navigate(targetUrl);
				};
				break;

			case TileGridType.BOUGHT_VIDEOS:
			case TileGridType.BOUGHT_ONLY_VIDEOS:
			case TileGridType.GIFTED_ONLY_VIDEOS:
			case TileGridType.PINBOARD_VIDEOS:
			case TileGridType.RECOMMENDATIONS_VIDEOS:
			case TileGridType.RECOMMENDATIONS_VIDEOS_SIMILAR:
				onClickFn = (e, albumData) => {
					if ((typeof e === 'object') && e.cancelable) {
						e.stopPropagation();
						e.preventDefault();
					}

					let targetUrl = albumData.targetUrl;
					if (!targetUrl) {
						targetUrl = MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_VIDEOALBUM_DETAIL, {
							':actorName': albumData.actorName,
							':albumId':   albumData.id,
						});
					}

					this.props.navigate(targetUrl);
				};
				break;

			case TileGridType.CATEGORY_CONTENT:
			case TileGridType.MY_VIDEOCHATS:
			case TileGridType.RECOMMENDATIONS_ACTORS:
			case TileGridType.RECOMMENDATIONS_BEST_CHATS:
			case TileGridType.TV_GIRLS:
			case TileGridType.TV_SHOWS:
			case TileGridType.TV_STARS:
				onClickFn = (e, actorData) => {
					if ((typeof e === 'object') && e.cancelable) {
						e.stopPropagation();
						e.preventDefault();
					}

					this.props.navigate(
						MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_OVERVIEW, {':actorName': actorData.name || actorData.actorName})
					);
				};
				break;

			case TileGridType.TV_ALL_SERIES:
			case TileGridType.TV_LATEST_SERIES:
				onClickFn = (e, item) => {
					if ((typeof e === 'object') && e.cancelable) {
						e.stopPropagation();
						e.preventDefault();
					}

					GuestAccess.onContentAccess({
						needAvs:     item.needAvs,
						needPremium: item.needPremium,
						needLogin:   (item.needAvs || item.needPremium),
					}, () => {
						this.props.navigate(
							MobileRoutes.getLink(MobileRoutes.TV_MEDIA_SERIES, {':key': item.formatKey})
						);
					});
				};
				break;

			case TileGridType.TV_ALL_VIDEOS:
			case TileGridType.TV_EPISODES:
			case TileGridType.TV_LATEST_SERIES_VIDEOS:
			case TileGridType.TV_LATEST_VIDEOS:
			case TileGridType.TV_RECOMMENDED_VIDEOS:
				onClickFn = (e, item) => {
					if ((typeof e === 'object') && e.cancelable) {
						e.stopPropagation();
						e.preventDefault();
					}

					GuestAccess.onContentAccess({
						needAvs:     item.needAvs,
						needPremium: item.needPremium,
						needLogin:   (item.needAvs || item.needPremium),
					}, () => {
						this.props.navigate(item.targetUrl);
					});
				};
				break;

			case TileGridType.TV_POSTS:
				onClickFn = (e, item) => {
					if ((typeof e === 'object') && e.cancelable) {
						e.stopPropagation();
						e.preventDefault();
					}

					GuestAccess.onContentAccess({
						needAvs:     item.needAvs,
						needPremium: item.needPremium,
						needLogin:   (item.needAvs || item.needPremium),
					}, () => {
						this.props.navigate(
							MobileRoutes.getLink(MobileRoutes.BLOG_ARTICLE, {':slug': item.id})
						);
					});
				};
				break;

			case TileGridType.CONTESTS_ACTIVE_CONTESTS:
			case TileGridType.CONTESTS_NEXT_CONTESTS:
			case TileGridType.CONTESTS_LAST_CONTESTS:
				onClickFn = (e, item) => {
					if ((typeof e === 'object') && e.cancelable) {
						e.stopPropagation();
						e.preventDefault();
					}

					if (item.targetUrl) {
						this.props.navigate(item.targetUrl);
					}
				};
				break;

			case TileGridType.MAGAZINE_MAGAZINES_CURRENT:
			case TileGridType.MAGAZINE_MAGAZINES_OTHERS:
				onClickFn = (e, magazineId) => {
					if ((typeof e === 'object') && e.cancelable) {
						e.stopPropagation();
						e.preventDefault();
					}

					const targetUrl = MobileRoutes.getLink(MobileRoutes.MAGAZINE_MAGAZINES_DETAILS, {
						':slug': magazineId + '-' + Translations.get('MagazineIssue'),
					});
					this.props.navigate(targetUrl);
				};
				break;

			case TileGridType.CATEGORIES:
			case TileGridType.FAVORITE_CATEGORIES:
				onClickFn = (e, targetUrl) => {
					if ((typeof e === 'object') && e.cancelable) {
						e.stopPropagation();
						e.preventDefault();
					}

					this.props.navigate(targetUrl);
				};
				break;

			default:
				break;
		}
		return onClickFn;
	}

	getPayload(page = null, sort = null, order = null, tag = null, filterOnline = null, premiumOnly = false, searchPattern = "") {
		if (this.state) {
			if (!page && this.state.currentPage) {
				page = this.state.currentPage;
			}

			if (!sort && sort !== '' && (this.state.sortKey || this.state.sortKey === '')) {
				sort = this.state.sortKey;
			}

			if (!order && this.state.order) {
				order = this.state.order;
			}

			if (filterOnline === null) {
				filterOnline = this.state.filterOnline;
			}

			// we always set from state
			if (!tag) {
				tag = this.state.tag;
			}

			if (!searchPattern) {
				searchPattern = this.state.searchPattern;
			}
		}

		// build payload
		this.payload          = assign({}, this.props.initialPayload, this.payload);
		this.payload['limit'] = this.props.tilesToShow;

		if (page) {
			this.payload['page'] = page;
		}

		if (sort !== null) {
			this.payload['sort'] = sort;
		}

		if (order) {
			this.payload['order'] = order;
		}

		this.payload['filterOnline']  = filterOnline;
		this.payload['tag']           = tag;
		this.payload['premiumOnly']   = premiumOnly;
        if (this.props.withTextSearch ){
            this.payload['searchPattern'] = searchPattern;
        }

		return this.payload;
	}

	loadGridConfig({useCache, page = null, sort = null, order = null, tag = null, filterOnline = null, premiumOnly = false, searchPattern = ''}) {
		useCache = typeof useCache === 'undefined' || useCache;

		const payload = this.getPayload(page, sort, order, tag, filterOnline, premiumOnly, searchPattern);
		if (this.props.type === Flux.Constants.TileGrid.PINBOARD_ALBUMS
			|| this.props.type === Flux.Constants.TileGrid.PINBOARD_PHOTOS
			|| this.props.type === Flux.Constants.TileGrid.PINBOARD_VIDEOS) {
			Flux.TileGrid.storePinBoardPayload(this.props.type, payload);
		}

		let gridConfig = null;
		if (useCache && Flux.TileGrid.hasGridData(this.props.type, payload)) {
			gridConfig = assign({}, Flux.TileGrid.getGridData(this.props.type, payload));
			if (this.props.notUsePjax) {
				gridConfig.usePjax = false;
			}
		} else {
			this.loading        = true;
			this.requestPayload = payload;
			GlobalEventHandler.emit(GlobalEventHandler.LOADER_TOGGLE, true);
			Flux.TileGrid.loadGrid(this.props.type, payload, this.props.useInfiniteScrolling || this.props.useLoadMorePagination);
		}
		return gridConfig;
	}

	loadMore() {
		if (this.state.maxPage > 1 && this.state.currentPage < this.state.maxPage) {
			this.paginateGotoFn(this.state.currentPage + 1);
		}
	}

	onCheckboxChange(checked, name) {
		this.setState({
			[name]: checked,
		});

		const gridConfig = this.loadGridConfig({useCache: this.useCache, page: 1, filterOnline: checked, searchPattern: this.state.searchPattern});
		if (gridConfig) {
			const state = TileGridBox.parseGridConfig(gridConfig);
			this.setState(state);
		}
	}

	onContainerScroll(pos, prevPos, containerHeight, contentHeight) {
		const scrollThreshold = contentHeight - containerHeight * 1.25; // trigger load under 75% of container height
		this.scrollPosition   = pos;
		if (typeof this.props.contentScrollCallback === 'function') {
			this.props.contentScrollCallback(pos);
		}
		if (pos > scrollThreshold && !this.loading) {
			this.loadMore();
		}
	}

	onGalleryChange(actorId, albumId) {
		if (this.state.gridConfig) {
			let hasChanged = false;

			for (let it = 0; it < this.state.gridConfig.initialTiles.length; it++) {

				const tile = this.state.gridConfig.initialTiles[it];
				if (tile.id === albumId) {

					// update needBuying and isAccessAllowed state of tile
					const galleryData    = Flux.Gallery.getGalleryData(albumId);
					tile.isAccessAllowed = galleryData.isAccessAllowed;
					tile.needBuying      = galleryData.needBuying;

					hasChanged = true;

					break;
				}
			}

			if (hasChanged) {
				this.setState({
					gridConfig: this.state.gridConfig,
				});
			}
		}
	}

	onPinnedMediaChange(pinType) {
		const payload = Flux.TileGrid.getPinBoardPayload(pinType);

		if (payload) {
			const gridConfig = assign({}, Flux.TileGrid.getGridData(pinType, payload));
			if (typeof gridConfig.totalCount !== 'undefined') {
				if (this.props.totalCountHook !== null) {
					// -1 as we substract each time the removed element from the total count
					this.props.totalCountHook(-1);
				}
				if (typeof gridConfig.headline !== 'undefined' && this.props.headlineHook !== null) {
					this.props.headlineHook(gridConfig.headline);
				}
				const state = TileGridBox.parseGridConfig(gridConfig, this.props.useInfiniteScrolling || this.props.useLoadMorePagination ? this.state : undefined);
				this.setState(state);

				if (pinType === Flux.Constants.TileGrid.PINBOARD_PHOTOS) {
					// to update the single pictures caroussel
					window.location.reload();
				}
			}
		}
	}

	onGridChange(type, error) {
		GlobalEventHandler.emit(GlobalEventHandler.LOADER_TOGGLE, false);
		if (type === this.props.type) {
			this.loading = false;

			if (!error) {

				const page         = this.requestPayload.page ? this.requestPayload.page : null;
				const sort         = this.requestPayload.sort ? this.requestPayload.sort : null;
				const order        = this.requestPayload.order ? this.requestPayload.order : null;
				const tag          = this.requestPayload.tag ? this.requestPayload.tag : null;
				const filterOnline = this.requestPayload.filterOnline ? this.requestPayload.filterOnline : null;
				const premiumOnly  = this.requestPayload.premiumOnly ? this.requestPayload.premiumOnly : false;
				const searchPattern  = this.requestPayload.searchPattern ? this.requestPayload.searchPattern : "";
				const gridConfig   = this.loadGridConfig({useCache: true, page, sort, order, tag, filterOnline, premiumOnly, searchPattern});
				if (this.props.parentCallback) {
					this.props.parentCallback(gridConfig);
				}

				if (this.props.filter) {
					gridConfig.initialTiles = gridConfig.initialTiles.filter(v => this.props.filter !== null ? v.contestMediaType === this.props.filter : true);
				}
				const state = TileGridBox.parseGridConfig(gridConfig, this.props.useInfiniteScrolling || this.props.useLoadMorePagination ? this.state : undefined);

				if (gridConfig && typeof gridConfig.totalCount !== 'undefined' && this.props.totalCountHook !== null) {
					this.props.totalCountHook(typeof gridConfig.onlineCount === 'number' ? gridConfig.onlineCount : gridConfig.totalCount);
				}

				if (gridConfig && typeof gridConfig.headline !== 'undefined' && this.props.headlineHook !== null) {
					this.props.headlineHook(gridConfig.headline);
				}

				this.setState(state);
			} else if (error && !this.gridConfig) {
				this.setState({
					gridConfig: {initialTiles: []},
				});
			}
		}
	}

	onGridModeChange(mode) {
		this.scrollContainerToAnchor();

		this.storeSettings({gridMode: mode});

		this.setState({
			gridMode: mode,
		});
	}

	onGridModeGridClick() {
		this.onGridModeChange(Flux.Constants.TileGridModes.GRID);
	}

	onGridModeListClick() {
		this.onGridModeChange(Flux.Constants.TileGridModes.LIST);
	}

	onResetClickFn() {
		const TileGridType = Flux.Constants.TileGrid;
		const actor        = this.state.gridConfig && this.state.gridConfig.mainActor ? this.state.gridConfig.mainActor : null;

		switch (this.props.type) {
			case TileGridType.LATEST_ALBUMS:
				if (actor) {
					this.props.navigate(
						MobileRoutes.getLink(MobileRoutes.ACTOR_PROFILE_ABOUT, {
							':actorName': actor.name,
						})
					);
					window.setTimeout(() => {
						scrollToElem(this.props.scrollAnchorId);
					}, 500);
				}
				break;
			default:
				break;
		}
	}

	onWindowResize() {
		this.setState({
			isLandscape: Flux.Browser.isLandscape(),
		});
	}

	paginateGotoFn(page) {
		if (!this.props.useInfiniteScrolling && !this.props.useLoadMorePagination) {
			this.scrollContainerToAnchor();
		}

		const gridConfig = this.loadGridConfig({useCache: this.useCache, page, premiumOnly: this.props.premiumOnly, searchPattern: this.state.searchPattern});
		if (gridConfig) {
			const state = TileGridBox.parseGridConfig(gridConfig, this.props.useInfiniteScrolling || this.props.useLoadMorePagination ? this.state : undefined);
			this.setState(state);
		}
	}

	scrollContainerToAnchor() {
		if (this.props.scrollContainerId) {
            console.log(this.props.scrollAnchorId);
            console.log(this.props.scrollContainerId);
			scrollContainerToElem(this.props.scrollAnchorId ? this.props.scrollAnchorId : this.state.id, this.props.scrollContainerId);
		}
	}

	sortGotoFn(item) {
		this.scrollContainerToAnchor();

		this.storeSettings({sort: item.sort, order: item.order, searchPattern: this.state.searchPattern});

		const page = this.props.usePagination || this.props.useInfiniteScrolling || this.props.useLoadMorePagination ? 1 : null;

		const gridConfig = this.loadGridConfig({useCache: this.useCache, page, sort: item.sort, order: item.order, searchPattern: this.state.searchPattern});
		if (gridConfig) {
			const state = TileGridBox.parseGridConfig(gridConfig);
			this.setState(state);
		}

		if (typeof this.props.onSortClickFn === 'function') {
			this.props.onSortClickFn(item);
		}
	}

    onSearchPatternChange(searchPattern) {
        this.scrollContainerToAnchor();

		this.storeSettings({sort: this.state.sortKey, order: this.state.order, searchPattern});

		const page = this.props.usePagination || this.props.useInfiniteScrolling || this.props.useLoadMorePagination ? 1 : null;

		const gridConfig = this.loadGridConfig({useCache: this.useCache, page, sort: this.state.sortKey, order: this.state.order, searchPattern});
		if (gridConfig) {
			const state = TileGridBox.parseGridConfig(gridConfig);
			this.setState(state);
		}
    }

	storeSettings(newSettings) {
		if (Flux.Guest.isLoggedIn() && this.props.storeSettings) {
			const settings = {
				gridMode:   this.state.gridMode,
				order:      this.state.order,
				sort:       this.state.sortKey,
                searchPattern: this.state.searchPattern,
			};

			if (typeof newSettings.gridMode !== 'undefined') {
				settings.gridMode = newSettings.gridMode;
			}

			if (typeof newSettings.order !== 'undefined') {
				settings.order = newSettings.order;
			}

			if (typeof newSettings.sort !== 'undefined') {
				settings.sort = newSettings.sort;
			}

            if (typeof newSettings.searchPattern !== 'undefined') {
				settings.searchPattern = newSettings.searchPattern;
			}

			Flux.VXMobile.Guest.storeTileGridSettingsByType(this.props.type, settings);
		}
	}

	toggleSearch() {
		this.setState({
			toggleSearch: !this.state.toggleSearch,
		});
	}

	render() {
		let tileGrid;
		let headline;
		let headlineContent;
		let headlineCount;
		let headlineDesc;
		let highlightTileGrid;
		let intermediateHeadline1;
		let intermediateHeadline2;
		let filter;
		let noResultContent;
		const hasFilter      = this.props.useOnlineFilter && this.state.gridConfig && this.state.gridConfig.allowFilterOnline;
		const hasModeSwitch  = this.props.useGridModeSwitch;
		const hasSelector    = this.state.sortOptions && this.props.useSorting;
		const showTotalCount = this.props.showTotalCount && this.state.gridConfig;
		const gridHeadline   = this.state.gridConfig && this.state.gridConfig.headline ? this.state.gridConfig.headline : '';
		const hasHeadline    = this.props.headline || gridHeadline;

		if (gridHeadline) {
			headlineContent = (
				<div className="tile-grid__headline--text" dangerouslySetInnerHTML={{__html: gridHeadline}} />
			);
		} else if (typeof this.props.headline === 'object') {
			headlineContent = this.props.headline;
		} else if (this.props.headline) {
			headlineContent = (
				<div className="tile-grid__headline--text" dangerouslySetInnerHTML={{__html: this.props.headline}} />);
		}


		if (showTotalCount) {
			headlineCount = (
				<div className="tile-grid__headline--count">
					({this.state.gridConfig.totalCount})
				</div>
			);
		}

		const descriptionText = this.props.description || (this.state.gridConfig && this.state.gridConfig.description);

		if (this.props.showDescription && descriptionText) {
			headlineDesc = (
				<div className={"tile-grid__headline--description" + (this.props.showDescriptionInHeader ? ' is-in-header' : '')}>
					{TranslationHelper.parseDescriptionText(descriptionText, 'h-text-highlight', this.onDescLinkClick)}
				</div>
			);
		}

		let selector;
		if ((hasHeadline || hasSelector || hasFilter || hasModeSwitch)) {

			if (hasSelector) {
				const number = this.state.gridConfig ? (this.state.gridConfig.totalCount || null) : null;
				selector     = <Sorter className="sim-selector--sorting"
				                       items={this.state.sortOptions}
				                       onItemClick={this.sortGotoFn}
				                       isMobile={true}
				                       isDisabled={!number}
				/>;
			}

			if (hasFilter) {
				const onlineFilter = {
					name:        'filterOnline',
					filterText:  Translations.get('Online'),
					sidebarText: Translations.get('Online'),
					field:       'actorOther',
					isActive:    this.state.filterOnline,
					callback:    this.onCheckboxChange,
				};

				const filterList = {
					quantity: this.state.gridConfig.totalCount,
					items:    [],
				};

				this.props.useOnlineFilter && filterList.items.push(onlineFilter);

				filter = <CategoriesFilter filterList={filterList} />;
			}

			let gridModeSwitch;
			if (hasModeSwitch) {
				const modeGridActive = this.state.gridMode === Flux.Constants.TileGridModes.GRID;
				const modeListActive = this.state.gridMode === Flux.Constants.TileGridModes.LIST;

				gridModeSwitch = (
					<div className="tile-grid__mode-switch">
						<i className={'icon -icon-categorie-tile tile-grid__mode' + (modeGridActive ? ' -is-active' : '')}
						   onClick={this.onGridModeGridClick}
						/>
						<i className={'icon -icon-categorie-line2 tile-grid__mode' + (modeListActive ? ' -is-active' : '')}
						   onClick={this.onGridModeListClick}
						/>
					</div>
				);
			}

			let headlineContainer;
			if (headlineContent || headlineCount) {
				headlineContainer = (
					<div>
						{headlineContent}
						{headlineCount}
						{this.props.showDescriptionInHeader && !this.state.isLandscape && headlineDesc}
					</div>
				);
			}

			let filterBoxClass = 'tile-grid__filter-box';
			if (hasModeSwitch) {
				filterBoxClass += ' -full-width';
			} else if (!headlineContainer) {
				filterBoxClass += ' -center';
			}
			if (this.props.useVXHeadline) {
				const number = this.state.gridConfig ? (this.state.gridConfig.totalCount || 0) : 0;
				headline     = (
					<>
						<Headline title={this.props.headline}
						          quantity={{number: number}}
						          class={this.props.hasBreadcrumb ? "vxheadline--no-padding-top" : ""}
						          additionalHeadline={this.props.additionalHeadline}
						          additionalHeadlineClass={this.props.additionalHeadlineClass}
						          titleType={this.props.titleType}
						>
							{gridModeSwitch}
							{selector}
						</Headline>
                       {this.props.withTextSearch && <div className='h-pl-15 h-pr-15 h-pb-20'>
                            <TextSearch
                                searchText={this.state.searchPattern}
                                doSearch={this.onSearchPatternChange}
                                isMobile={true}
                                isMyVisitX={false}
                                searchEmpty={true}
                                lightTheme={true}
                            />
                        </div>}
						{descriptionText}
					</>
				);

			} else {
				headline = (
					<div className={'tile-grid__headline -default-margin' + (hasSelector || hasFilter ? ' has-selector' : '') + (headlineContent ? ' has-headline' : '') + (this.props.useLongHeadline ? ' has-long-headline' : '')}>
						{headlineContainer}
						<div className={filterBoxClass}>
							{selector}
							{gridModeSwitch}
							{this.state.gridConfig && this.state.gridConfig.searchTags && this.state.gridConfig.searchTags.length > 0 &&
								<div className="tile-grid__search-keyword">
									<span className="icon -icon-search-full tile-grid__search-icon"></span>
									<span className="actor-content-placeholder__text-headline">{Translations.get('Search')}</span>
									<span className="content-box__caption h-color-highlight-dark tile-grid__search-field">„{this.state.gridConfig.searchTags[0]}“</span>
									<a onClick={this.onTagReset}><span className="actor-content-placeholder__delete-search tile-grid__search-field">{Translations.get('DeleteSearch')}</span></a>
								</div>}
						</div>
						{(!this.props.showDescriptionInHeader || this.state.isLandscape) && headlineDesc}
					</div>
				);
			}
		}

		if (this.state.gridConfig === null) {
			tileGrid = (
				<div className="tile-grid__loader">
					{this.props.loader !== null ? this.props.loader : <CircleLoader />}
				</div>
			);
		} else if (this.state.gridConfig.initialTiles) {
			let initialTiles;
			if (this.props.highlightCount > 0 && this.state.gridConfig.initialTiles.length > this.props.highlightCount) {
				if (this.props.intermediateHeadline1) {
					const useSorter    = !this.props.headline && this.props.useSorting;
					let promotionBadge = null;
					if (this.props.promotionBadgeUrl) {
						promotionBadge = <img className={"promotion-badge right-10"} src={this.props.promotionBadgeUrl} />;
					}
					intermediateHeadline1 =
						<div className="tile-grid__intermediate-headline -line1">{this.props.intermediateHeadline1} {useSorter && selector}{promotionBadge}</div>;
				}

				if (this.props.intermediateHeadline2) {
					intermediateHeadline2 = <div className="tile-grid__intermediate-headline -line2">{this.props.intermediateHeadline2}</div>;
				}

				initialTiles = this.state.gridConfig.initialTiles.slice(this.props.highlightCount);

				highlightTileGrid = <TileGrid cols={this.props.highlightCols ? this.props.highlightCols : this.props.initialCols}
				                              autoAnimateInView={this.props.autoAnimateInView}
				                              contentBoxClassModifier={this.props.contentBoxClassModifier}
				                              dummyTileClass={this.props.dummyTileClass}
				                              gridConfig={this.state.gridConfig}
				                              guestIsLoggedIn={Flux.Guest.isLoggedIn()}
				                              proSearchActiveCategory={this.props.proSearchActiveCategory}
				                              onButtonClickFn={this.getOnButtonClickFn()}
				                              onClickFn={this.getOnClickFn()}
				                              onResetClickFn={this.onResetClickFn}
				                              isMobile={true}
				                              useTileWithSecondary={this.props.useTileWithSecondary}
				                              gridMode={this.state.gridMode}
				                              tiles={this.state.gridConfig.initialTiles.slice(0, this.props.highlightCount)}
				                              showBadges={true}
				                              useThumbsVoting={this.props.useThumbsVoting}
				/>;
			}

			tileGrid = <TileGrid cols={this.props.initialCols}
			                     autoAnimateInView={this.props.autoAnimateInView}
			                     contentBoxClassModifier={this.props.contentBoxClassModifier}
			                     dummyTileClass={this.props.dummyTileClass}
			                     gridConfig={this.state.gridConfig}
			                     guestIsLoggedIn={Flux.Guest.isLoggedIn()}
			                     proSearchActiveCategory={this.props.proSearchActiveCategory}
			                     onButtonClickFn={this.getOnButtonClickFn()}
			                     onClickFn={this.getOnClickFn()}
			                     onResetClickFn={this.onResetClickFn}
			                     isMobile={true}
			                     initialClassName={this.props.tileGridClassName}
			                     showAmateurSearchButton={true}
			                     Link={Link}
			                     routes={MobileRoutes}
			                     maxEntriesForPlaceholder={this.props.maxEntriesForPlaceholder}
			                     useTileWithSecondary={this.props.useTileWithSecondary}
			                     gridMode={this.state.gridMode}
			                     tiles={initialTiles ? initialTiles : this.state.gridConfig.initialTiles}
			                     showBadges={true}
			                     useThumbsVoting={this.props.useThumbsVoting}
			                     lazyImageLoading={this.props.lazyImageLoading}
			/>;
		} else if (this.state.gridConfig.initialTiles.length === 0 && hasFilter && this.props.placeholder) {
			tileGrid = this.props.placeholder;
		}

		let pagination;
		if (this.state.maxPage > 1 && this.props.usePagination) {
			pagination = (
				<div className="tile-grid__pagination">
					<Pagination currentPage={this.state.currentPage}
					            maxPage={this.state.maxPage}
					            goToFn={this.paginateGotoFn}
					            isMobile={true}
					            inline={true}
					            scrollOnGoTo={this.props.scrollToId}
					            containerId={this.props.scrollToId}
					/>
				</div>
			);
		} else if (this.props.useLoadMorePagination && this.state.maxPage > 1 && this.state.currentPage < this.state.maxPage) {
			pagination = (
				<div className="tile-grid__pagination">
					<div className="pagination">
						<button className="btn -btn-color-default" onClick={this.loadMore}>{Translations.get('LoadMore')}</button>
					</div>
				</div>
			);
		}

		if (typeof this.props.noResultContent === 'function' && this.state.gridConfig && this.state.gridConfig.totalCount < 1) {
			noResultContent = this.props.noResultContent();
		}

		return (
			<div id={this.state.id}
			     className={'tile-grid__container' + (this.props.className ? ' ' + this.props.className : '')}
			>
				{headline}
				{noResultContent}
				{filter}
				{intermediateHeadline1}
				{highlightTileGrid}
				{intermediateHeadline2}
				{tileGrid}
				{pagination}
			</div>
		);
	}
}

TileGridBox.propTypes = {
	autoAnimateInView:        PropTypes.bool,
	className:                PropTypes.string,
	contentBoxClassModifier:  PropTypes.string,
	defaultSort:              PropTypes.string,
	description:              PropTypes.string,
	dummyTileClass:           PropTypes.string,
	headline:                 PropTypes.string,
	headlineHook:             PropTypes.func,
	highlightCols:            PropTypes.number,
	highlightCount:           PropTypes.number,
	initialCols:              PropTypes.number,
	initialPayload:           PropTypes.object,
	initialTag:               PropTypes.string,
	intermediateHeadline1:    PropTypes.object,
	intermediateHeadline2:    PropTypes.object,
	loader:                   PropTypes.element,
	maxEntriesForPlaceholder: PropTypes.number,
	onSortClickFn:            PropTypes.func,
	placeholder:              PropTypes.node,
	proSearchActiveCategory:  PropTypes.string,
	scrollAnchorId:           PropTypes.string,
	scrollContainerId:        PropTypes.string,
	showDescription:          PropTypes.bool,
	showDescriptionInHeader:  PropTypes.bool,
	showTotalCount:           PropTypes.bool,
	storeSettings:            PropTypes.bool,
	tileGridClassName:        PropTypes.string,
	tileGridId:               PropTypes.string,
	tilesToShow:              PropTypes.number,
	totalCountHook:           PropTypes.func,
	type:                     PropTypes.string.isRequired,
	useCache:                 PropTypes.bool,
	useGridModeSwitch:        PropTypes.bool,
	useInfiniteScrolling:     PropTypes.bool,
	useLoadMorePagination:    PropTypes.bool,
	useLongHeadline:          PropTypes.bool,
	useOnlineFilter:          PropTypes.bool,
	usePagination:            PropTypes.bool,
	useSorting:               PropTypes.bool,
	useTileWithSecondary:     PropTypes.bool,
	premiumOnly:              PropTypes.bool,
	hasBreadcrumb:            PropTypes.bool,
	useVXHeadline:            PropTypes.bool,
	useThumbsVoting:          PropTypes.bool,
	scrollToId:               PropTypes.string,
	notUsePjax:               PropTypes.bool,
	contentScrollCallback:    PropTypes.func,
	additionalHeadline:       PropTypes.node,
	additionalHeadlineClass:  PropTypes.string,
	noResultContent:          PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	parentCallback:           PropTypes.func,
	filter:                   PropTypes.string,
	lazyImageLoading:         PropTypes.bool,
	promotionBadgeUrl:        PropTypes.string,
	titleType:                PropTypes.string,
    withTextSearch:           PropTypes.bool,
    navigate:                 PropTypes.func,
};

TileGridBox.defaultProps = {
	autoAnimateInView:       false,
	defaultSort:             'released',
	description:             '',
	headline:                '',
	headlineHook:            null,
	highlightCols:           0,
	highlightCount:          0,
	initialCols:             1,
	initialPayload:          {},
	initialTag:              null,
	intermediateHeadline1:   null,
	intermediateHeadline2:   null,
	scrollToId:              '',
	loader:                  null,
	showDescription:         false,
	showDescriptionInHeader: false,
	showTotalCount:          false,
	storeSettings:           false,
	tileGridId:              null,
	tilesToShow:             Flux.Constants.VXMobile.RecommendationsPerGrid,
	totalCountHook:          null,
	useCache:                false,
	useGridModeSwitch:       false,
	useInfiniteScrolling:    false,
	useLoadMorePagination:   false,
	useLongHeadline:         false,
	useOnlineFilter:         false,
	usePagination:           false,
	useSorting:              false,
	useTileWithSecondary:    false,
	premiumOnly:             false,
	hasBreadcrumb:           true,
	useVXHeadline:           false,
	useThumbsVoting:         false,
	notUsePjax:              false,
	contentScrollCallback:   null,
	additionalHeadline:      null,
	additionalHeadlineClass: '',
	noResultContent:         null,
	lazyImageLoading:        false,
	titleType:               'span',
    withTextSearch:          false,
};

export default withNavigate(TileGridBox);
