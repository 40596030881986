import React, {useState, useEffect} from 'react';
import PropTypes                    from 'prop-types';
import {Link}                       from 'react-router-dom';
import MobileRoutes                 from '../Router/MobileRoutes';
import SidebarWrapper, {FROM}       from './SidebarWrapper';
import {getMenuItems,
	    toggleMenu}                 from '../Navigation/Helper';
import Login                        from '../Navigation/Login';
import Footer                       from '../Footer/Footer';
import Flux                         from '../../../flux/Flux';
import { generateKey } 				from '../../../utils/CommonUtils';


function getItems(items, forwardFunction, selectedMenu, isLoggedIn) {
	const tmp = [];
	const onClickMenu = (item, event) => {
		event.preventDefault();
		forwardFunction(item.subMenu);
		selectedMenu(item.name);
	};

	items.filter(item => item.needLogin ? isLoggedIn : true).map((item, idx) => {
		const hasSubmenu =  item.subMenu && item.subMenu.length > 0;
		const hasCallback = typeof item.callback === 'function';

		const icon = item.icon && <i className={"vxmenu__menu-icon vxmenu__menu-icon--ml-10 icon " + item.icon + (item.isVip ? ' vxmenu__menu-icon--gold' : '')}></i>;
		tmp.push(
			<li key={generateKey('vxmenu-item' + idx, tmp.length)}>
				<Link to={hasSubmenu ? '#' : (item.link ? item.link : '#')} className={"vxmenu__menu-item" + (item.isVip ? ' vxmenu__menu-item--gold' : '')} onClick={(event) => hasSubmenu ? onClickMenu(item, event) : (hasCallback ? item.callback() : Flux.VXMobile.closeMenu(Flux.Constants.VXMobile.Menus.NAVI))}>
					<span>{item.name} {icon}</span>
					{hasSubmenu && <i className="vxmenu__menu-icon icon -icon-single-arrow-right-line"></i>}
				</Link>
			</li>
		);
	});

	return tmp;
}

function onCloseClick() {
	toggleMenu(Flux.Constants.VXMobile.Menus.NAVI);
}

function MenuSidebar({isMenuOpen, isLoggedIn}) {
	const menuItems                             = getMenuItems();
	const [selectedMenu, setSelectedMenu]       = useState('');
	const [selectedSubMenu, setSelectedSubMenu] = useState('');
	const [subMenuItems, setSubMenuItems]       = useState([]);
	const [subSubMenuItems, setSubSubMenuItems] = useState([]);

	useEffect(() => {
		if (!isMenuOpen) {
			setTimeout(() => {
				setSubMenuItems([]);
			}, 250);
		}
	}, [isMenuOpen]);

	const SubSubMenu = (
		<SidebarWrapper openMenu={subSubMenuItems.length > 0} class="--sub-menu" showShadow={false}>
			<nav className={"vxmenu vxmenu--hidden" + (isLoggedIn ? "" : " vxmenu--padding-bottom")}>
				<ul className="vxmenu__menu vxmenu--scroll">
					<li>
						<Link to="#" className="vxmenu__menu-item vxmenu__menu-item--left" onClick={(event) => {event.preventDefault(); setSubSubMenuItems([]);}}>
							<i className="vxmenu__menu-icon vxmenu__menu-icon--mr-10 icon -icon-single-arrow-left-line"></i>
							{selectedSubMenu}
						</Link>
					</li>
					{getItems(subSubMenuItems, null, null, isLoggedIn)}
				</ul>
			</nav>
		</SidebarWrapper>
	);

	const SubMenu = (
		<SidebarWrapper openMenu={subMenuItems.length > 0} class="--sub-menu" showShadow={false}>
			<nav className={"vxmenu vxmenu--hidden" + (isLoggedIn ? "" : " vxmenu--padding-bottom")}>
				<ul className="vxmenu__menu vxmenu--scroll">
					<li>
						<Link to="#" className="vxmenu__menu-item vxmenu__menu-item--left" onClick={(event) => {event.preventDefault(); setSubMenuItems([]);}}>
							<i className="vxmenu__menu-icon vxmenu__menu-icon--mr-10 icon -icon-single-arrow-left-line"></i>
							{selectedMenu}
						</Link>
					</li>
					{getItems(subMenuItems, setSubSubMenuItems, setSelectedSubMenu, isLoggedIn)}
				</ul>
			</nav>
			{SubSubMenu}
		</SidebarWrapper>
	);

	const MainMenu = (
		<SidebarWrapper openMenu={isMenuOpen} from={FROM.RIGHT}>
			<nav className={"vxmenu vxmenu--hidden" + (isLoggedIn ? "" : " vxmenu--padding-bottom")}>
				<div className="vxmenu__top">
					<Link to={MobileRoutes.getLink(MobileRoutes.VXMOBILE) || '#'}><i className="vxmenu__menu-icon icon -icon-home-full"></i></Link>
					<i className='vxmenu__menu-close icon -icon-close-line' onClick={onCloseClick}></i>
				</div>
				<ul className="vxmenu__menu vxmenu--scroll">
					{getItems(menuItems, setSubMenuItems, setSelectedMenu, isLoggedIn)}
					<li>
						<Footer />
					</li>
				</ul>
			</nav>
			{SubMenu}
			{!isLoggedIn && <Login position="bottom" />}
		</SidebarWrapper>
	);

	return MainMenu;
}

MenuSidebar.propTypes = {
	isMenuOpen: PropTypes.bool,
	isLoggedIn: PropTypes.bool,
};

export default MenuSidebar;

