import React                      from "react";
import StatusBarAutomaticRecharge from './StatusBarAutomaticRecharge.js';
import Constants                  from '../../flux/Constants.js';
import PropTypes                  from 'prop-types';

const types = Constants.StatusBar.Types;

const StatusBarWrapper = (props) => {
    const {config = {}} = props;
    let statusBar       = null;

    switch (config.barType) {
        case types.BARTYPE_AUTOMATIC_RECHARGE:
            statusBar =
                    <StatusBarAutomaticRecharge
                            barColor={config.barColor}
                            hideText={config.hideText}
                            btnLabel={config.buttonLabel}
                            msgIntro={config.introText}
                            msgOutro={config.outroText}
                            isVisible={config.isStatusBarVisible}
                    />;
            break;
    }

    return statusBar ? <div className="status-bar__wrapper">{statusBar}</div> : null;
};

StatusBarWrapper.propTypes = {
    config: PropTypes.shape({
        barColor:  PropTypes.string,
        msgIntro:  PropTypes.string,
        msgOutro:  PropTypes.string,
        btnLabel:  PropTypes.string,
        isVisible: PropTypes.bool,
        children:  PropTypes.node,
        hideText:  PropTypes.string,
    }),
};

export default StatusBarWrapper;
