import React     from 'react';
import PropTypes from 'prop-types';

const CLASS = "sidebar__wrapper";
let isInit  = false;

const FROM = {
	TOP:    'top',
	LEFT:   'left',
	RIGHT:  'right',
	BOTTOM: 'bottom',
};

function SidebarWrapper(props) {
	const classes     = [CLASS];
	props.class       && classes.push(CLASS + props.class);
	props.openMenu    && classes.push(CLASS + '--open');
	props.from        && classes.push(CLASS + `--from-${props.from}`);
	props.showHeader  && classes.push(CLASS + '--top');
	!props.showNavbar && classes.push(CLASS + '--bottom');
	props.darkBg      && classes.push(CLASS + '--dark-bg');
	isInit            && classes.push(CLASS + '--init');
	if (props.openMenu && !isInit) {
		classes.push(CLASS + '--init');
		isInit = true;
	}	
	return (
		<div className={classes.join(' ')}>
			{props.showShadow && <div className="sidebar__wrapper-shadow"></div>}
			{props.children}
		</div>
	);
}

SidebarWrapper.propTypes = {
	showShadow: PropTypes.bool,
	showHeader: PropTypes.bool,
	showNavbar: PropTypes.bool,
	children:   PropTypes.node,
	openMenu:   PropTypes.bool,
	darkBg:     PropTypes.bool,
	class:      PropTypes.string,
	from:       PropTypes.oneOf([...Object.values(FROM)]),
};

SidebarWrapper.defaultProps = {
	showShadow:  true,
	showHeader:  false,
	showNavbar:  true,
	darkBg:      false,
	from:        FROM.LEFT,
};


export default SidebarWrapper;
export { FROM };

