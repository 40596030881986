import React                                        from 'react';
import PropTypes                                    from 'prop-types';
import PreviewPictures                              from "./Base/Preview/PreviewPictures";
import {TileDisplayConfigType}                      from "../Grid2";
import BlogTileFooter, {BlogTileFooterConfigType}   from "./BlogTileFooter";
import HoverContainer                               from "./Base/HoverContainer";
import ReloadHelper                                 from "../../../utils/ReloadHelper";

const BlogTile = props => {
	let data          = props.data;
	if(props.data.blogPost !== undefined){
		data          = props.data.blogPost;
	}
	const displayConfig = props.displayConfig;
	const config        = props.config;
	const linkUrl = data.linkVX;
	const onClick = e => ReloadHelper.reload(linkUrl, e);
	const onClickCallback = (e) => {
		e.preventDefault();
		data.callback(data);
	};
	const preview = {
		aspectRatio: 1.333333,
		id: 0,
		images: [
			{
				size: "w320",
				url: data.previewPicture,
			},
		],
		title: data.title,

	};

	return (
		<a className={'grid-2__tile__type-video'} href={linkUrl} onClick={data.callback ? onClickCallback : onClick}>
			<HoverContainer>
				<PreviewPictures staticItem={preview}
				                 items={data.previewThumbnails}
				                 displayConfig={displayConfig}
				/>
			</HoverContainer>
			<BlogTileFooter data={data} displayConfig={displayConfig} config={config.footer} />
		</a>
	);
};

BlogTile.propTypes = {
	data:          PropTypes.object.isRequired, // VXQL Video type
	config:        PropTypes.shape({
		footer: BlogTileFooterConfigType,
	}).isRequired,
	displayConfig: TileDisplayConfigType.isRequired,
};

BlogTile.defaultProps = {
	config: {
		footer: BlogTileFooter.defaultProps.config,
	},
};

export default BlogTile;