/* global System */

import WebPushHelper from './../../utils/WebPushHelper';

/**
 * @param {String} cachingTag
 */
function run(cachingTag) {
	if (WebPushHelper.hasServiceWorkerSupport()) {
		if (navigator.serviceWorker.controller) {
			setTimeout(triggerCacheCleanUp, 2000);
		} else {
			navigator.serviceWorker.oncontrollerchange = function() {
				this.controller.onstatechange = function() {
					if (this.state === 'activated') {
						triggerCacheCleanUp();
					}
				};
			};
		}

		// register worker + request notifications permissions
		WebPushHelper.registerWorker(WebPushHelper.WORKER_MOBILE);
	}

	function triggerCacheCleanUp() {
		if (typeof System !== 'undefined' && Object.keys(System.bundles).length) {
			navigator.serviceWorker.controller.postMessage({
				type:       'cacheCleanUp',
				bundles:    System.bundles,
				configFile: 'js/config-prod.js?ver=' + cachingTag,
			});
		} else {
			setTimeout(triggerCacheCleanUp, 2000);
		}
	}
}

export {run};
